import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import UserPanel from "./userPanel";

const Mypreferences = (props) => {
  const { authState } = useContext(AuthContext);
  const [preferenceList, setPreferenceList] = useState(
    authState.user.mealPreferences
  );
  // const values = authState.user.mealPreferences;
  const userId = authState.user._id;
  const prefs = [
    "family friendly",
    "kid friendly",
    "low carb",
    "vegetarian",
    "pescatarian",
    "calorie smart",
    "classics",
    "guilt free",
    "chicken",
    "meat",
    "gourmet",
  ];
  const [updateMessage, setUpdateMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    "family friendly": false,
    "kid friendly": false,
    "low carb": false,
    vegetarian: false,
    pescatarian: false,
    "calorie smart": false,
    classics: false,
    "guilt free": false,
    chicken: false,
    meat: false,
    gourmet: false,
  });

  const navigateTo = useNavigate();

  useEffect(() => {
    document.title = "My Preferences";
    const initialCheckedState = {};
    for (let i = 0; i < prefs.length; i++) {
      if (authState.user.mealPreferences.includes(prefs[i])) {
        initialCheckedState[prefs[i]] = true;
      } else {
        initialCheckedState[prefs[i]] = false;
      }
    }

    setChecked(initialCheckedState); // Set initial checked state once
  }, []);

  const dialogRef = useRef();
  const toggleDialog = async function (id) {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.classList.add("closing")
      : dialogRef.current.classList.remove("closing");
    dialogRef.current.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();

    if (dialogRef.current.hasAttribute("open")) {
    }
  };

  const reroute = () => {
    navigateTo("/dashboard");
  };

  const handlepreferenceChange = (e, index) => {
    const { value, checked } = e.target;
    if (checked) {
      setPreferenceList((prevpreferenceList) => [value, ...prevpreferenceList]);
      setChecked((prevChecked) => ({
        ...prevChecked,
        [value]: true,
      }));
    } else {
      var filteredArray = preferenceList.filter(
        (preferenceList) => preferenceList !== value
      );
      setPreferenceList((prevState) => filteredArray);
      setChecked((prevChecked) => ({
        ...prevChecked,
        [value]: false,
      }));
    }
  };

  const handleClick = async (e) => {
    toggleDialog();
    setLoading(true);
    try {
      await axios
        .put(`/api/auth/updateUserPrefSelections/${userId}`, {
          mealPreferences: preferenceList,
        })
        .then((response) => {
          setUpdateMessage(response.data.message);

          setLoading(false);

          //props.tabsetting('plansettings')
        });
    } catch (error) {
      console.error(`Error updating meal selections:`, error);
    }
  };

  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          <div className="userData">
            <div className="container">
              <div className="headerSection">
                <h1>Preferences</h1>
                <h2>Select your preferences</h2>
                <Link to={"/dashboard"}>
                  <button className="btd">Back to dashboard</button>
                </Link>
              </div>
              <div className="boxesPeople">
                <div className="checkboxes-group">
                  {prefs.map((category, idx) => (
                    <div key={idx}>
                      <input
                        type="checkbox"
                        key={idx}
                        value={category}
                        id={category}
                        name={category}
                        onChange={handlepreferenceChange}
                        checked={checked[category]}
                      />{" "}
                      <label
                        className={`preference ${category.replace(/ /g, "")}`}
                        htmlFor={category}
                      >
                        {category}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="saveButtons">
                <button className="nextButton" onClick={handleClick}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dialog className="filter" ref={dialogRef} id="PaymentRef">
        <div className="modalTop">
          <span>Processing Preferences</span>
          <button
            className="close"
            onClick={() => {
              toggleDialog();
              reroute();
            }}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{updateMessage}</p>
          )}
        </div>
      </dialog>
    </>
  );
};

export default Mypreferences;
