import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/modal.css";

const MealModal = ({ meal, onClose }) => {
  const location = useLocation();
  // Extract base name and extension from the image file name
  const baseNameF = meal.image.split(".")[0];
  const ext = meal.image.split(".")[1];
  //const baseName = `${baseNameF}-x.${ext}`; // Add '-x' to baseName
  const baseName = `${baseNameF}.${ext}`; // Add '-x' to baseName
  const shouldShow = location.pathname === "/themeals";
  // Initialize state for showing the modal
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Set show to true when the component mounts
  }, []);

  // Handle the closing of the modal
  const handleClose = () => {
    setShow(false); // Set show to false to start the hide transition
    setTimeout(() => {
      onClose(); // Call onClose after the transition duration
    }, 50); // Match this duration with the CSS transition duration
  };

  // Return null if there is no meal data
  if (!meal) return null;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div
        className={`modal-container ${show ? "show" : "hide"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="modal-close" onClick={handleClose}>
          &times; Close
        </button>
        <div className="modal-body">
          <div className="imageContainer">
            <img src={`/images/meals/${baseName}`} alt={meal.title} />
          </div>
          <ul className="mealTypes">
            {meal.mealtype
              .toString()
              .split(",")
              .map((type, index) => (
                <li key={index} className={type.replace(/ /g, "")}>
                  {type}
                </li>
              ))}
          </ul>
          <div className="blurb">
            <h2>{meal.title}</h2>
            <h3>{meal.subtitle}</h3>
            <p>{meal.desc}</p>
          </div>
          <div className="modal-content">
            <div className="modal-section">
              <h4>Ingredients</h4>
              <ul>
                {meal.ingredients.map(
                  (ingredient, index) =>
                    ingredient.name && (
                      <li key={index}>{ingredient.name.trim()}</li>
                    )
                )}
              </ul>
            </div>
            <div className="modal-section">
              <h4>Allergens</h4>
              <ul>
                {meal.allergens &&
                  meal.allergens
                    .split(",")
                    .map((type, index) => <li key={index}>{type.trim()}</li>)}
              </ul>
            </div>
            <div className="modal-section">
              <h4>Nutrition</h4>
              <ul>
                <li>Protein: {meal.protein}</li>
                <li>Calories: {meal.energykcal}</li>
                <li>Fat: {meal.fat}</li>
                <li>Carbohydrates: {meal.carbs}</li>
                <li>Of which sugars: {meal.ofwhichsugars}</li>
                <li>Sodium: {meal.salt}</li>
              </ul>
            </div>
            <div className="modal-section">
              <h4>Utensils</h4>
              <ul>
                {meal.utensils &&
                  meal.utensils
                    .split(",")
                    .map((type, index, array) => (
                      <li key={index}>{type.trim()}</li>
                    ))}
              </ul>
            </div>
          </div>
          {shouldShow && (
            <div className="modal-signup">
              <div>
                <img src="/images/signup.png" alt="sign up" />
              </div>
              <div>
                <p>Start your Cookit journey</p>
                <button>
                  <Link to="/boxes">Create your box</Link>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MealModal;
