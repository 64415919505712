import axios from "axios";
import { useState, useEffect } from "react";
import AdminPanel from "./adminPanel";

const Deliveries = () => {
  const [usersByDeliveryDay, setUsersByDeliveryDay] = useState({});
  const [delslotTime, setDelslotTime] = useState("");
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    document.title = "Deliveries";

    const fetchData = async () => {
      try {
        // Fetch users and meals data
        const [usersRes, mealsRes] = await Promise.all([
          axios.get("/api/auth/allusers"),
          axios.get("/api/auth/allmeals"),
        ]);

        const usersData = usersRes.data;
        const mealsData = mealsRes.data;

        // Create a mapping of meal IDs to meal details
        const mealMap = mealsData.reduce((acc, meal) => {
          acc[meal._id] = meal;

          return acc;
        }, {});

        // Replace mealSelections IDs with the corresponding meal details
        const usersWithMealDetails = usersData.map((user) => {
          return {
            ...user,
            mealSelections: user.mealSelections.map(
              (mealId) => mealMap[mealId]
            ),
          };
        });

        // Group users by deliveryDay
        const groupedUsers = usersWithMealDetails.reduce((acc, user) => {
          const day = user.deliveryDay;
          if (!acc[day]) {
            acc[day] = [];
          }
          acc[day].push(user);
          return acc;
        }, {});

        setUsersByDeliveryDay(groupedUsers);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("/api/auth/admindel");
        console.log(response.data.orders);
        const groupedOrders = groupOrdersByWeek(response.data.orders);
        setOrders(groupedOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const groupOrdersByWeek = (orders) => {
    const grouped = {};
    console.log("Orders:", orders);

    orders.forEach((order) => {
      order.mealsByStartDate.forEach((meal) => {
        if (!grouped[meal.weekstarting]) {
          grouped[meal.weekstarting] = {};
        }
        if (!grouped[meal.weekstarting][meal.deliveryDay]) {
          grouped[meal.weekstarting][meal.deliveryDay] = [];
        }
        const mealSelections = Array.isArray(meal.mealSelections)
          ? meal.mealSelections
          : [meal.mealSelections];
        const mealDetails = mealSelections.map((selection) => ({
          count: selection.count,
          title: selection.meal.title,
          image: selection.meal.image,
          desc: selection.meal.desc,
          allergens: selection.meal.allergens,
          carbs: selection.meal.carbs,
          energykcal: selection.meal.energykcal,
          fat: selection.meal.fat,
          protein: selection.meal.protein,
          salt: selection.meal.salt,
          subtitle: selection.meal.subtitle,
          utensils: selection.meal.utensils,
          ins1: selection.meal.ins1,
          ins2: selection.meal.ins2,
          ins3: selection.meal.ins3,
          ins4: selection.meal.ins4,
          ins5: selection.meal.ins5,
          ins6: selection.meal.ins6,
        }));

        grouped[meal.weekstarting][meal.deliveryDay].push({
          ...meal,
          userDetails: order.userDetails,
          total: order.total,
          orderID: order._id,
          mealDetails: mealDetails,
        });
      });
    });

    return grouped;
  };

  const groupedOrders = groupOrdersByWeek(orders);

  const DelSlotFunc = ({ userSlot }) => {
    userSlot = parseInt(userSlot);
    switch (userSlot) {
      case 1:
        return "8:00 - 10:00am";
      case 2:
        return "10:00 - 12:00am";
      case 3:
        return "12:00 - 6:00pm";
      case 4:
        return "12:00 - 6:00pm";
      default:
        return "Unknown time";
    }
  };

  return (
    <div className="adminDash">
      <h1>Deliveries</h1>
      <AdminPanel />
      <div className="adminMainContent">
        <div className="dashboard">
          <div>
            {Object.keys(groupedOrders).length > 0 ? (
              Object.keys(groupedOrders).map((week) => (
                <div key={week} className="wkdeliveryDay">
                  <h2>Week Starting: {week}</h2>
                  {Object.keys(groupedOrders[week]).map((day) => (
                    <div key={day}>
                      <h3>{day}</h3>
                      <table className="orderTable">
                        <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Customer Name</th>
                            <th>Delivery Day</th>
                            <th>Delivery Date</th>
                            <th>Delivery Time Slot</th>
                            <th>Servings</th>
                            <th>Meal Selections</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedOrders[week][day].map((meal, index) => (
                            <tr key={index} className="orderCard">
                              <td>{meal.orderID}</td>
                              <td>
                                {" "}
                                {meal.userDetails.firstName}{" "}
                                {meal.userDetails.lastName}
                              </td>

                              <td>{meal.deliveryDay}</td>
                              <td>{meal.deliveryDate}</td>
                              <td>
                                {DelSlotFunc({
                                  userSlot: meal.deliverTimeSlot,
                                })}
                              </td>
                              <td>{meal.servings}</td>
                              <td>
                                {/* <ul>
                                  {meal.mealDetails.map((selection, i) => (
                                    <li key={i}>{selection.title}</li>
                                  ))} 
                                </ul>*/}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>No orders available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliveries;
