import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import axios from "axios";
import WeeklyDateRanges from "../components/weeklydaterange";
import { AuthContext } from "../context/AuthContext";
import "../styles/meals.css";
import Loading from "./loading";
import MealModal from "./mealModal";
import Modal from "./modal";
import calculateUpcomingDay from "./deliverydays";

const MyMeals = () => {
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [pricepermeal, setPricepermeal] = useState(50);
  const [gourmetPrice, setGourmetPrice] = useState(10);
  const [people, setPeople] = useState(authState.user.noPeople);
  const [nmeals, setNmeals] = useState(authState.user.noMeals);
  const [credit, setCredit] = useState(0);
  const [originalCredit, setOriginalCredit] = useState(authState.user.credit);
  const location = useLocation();
  const [MealsList, setMealsList] = useState([]);
  const [keys, setKeys] = useState([]);
  const [mealsCount, setMealsCount] = useState(0);
  const [filteredMealsList, setFilteredMealsList] = useState([]);
  const preferences = authState.user.mealPreferences;
  const targetDay = authState.user.deliveryDay.toLowerCase();

  // let meals = authState.user.noMeals;
  const [values, setValues] = useState([]);
  const userId = authState.user._id;
  const [mealSelections, setMealSelections] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateused, setDateused] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null); // Change state to store selected meal object
  const [mealSelectionsByDate, setMealSelectionsByDate] = useState({});
  const [currentSelections, setCurrentSelections] = useState([]);
  const [mealCounts, setMealCounts] = useState({});
  const [mostRecentMonday, setmostRecentMonday] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [orderCost, setOrderCost] = useState(0);
  const [originalOrderCost, setOriginalOrderCost] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [originaltotalOrderCost, setOriginaltotalOrderCost] = useState(0);
  const [orderDifference, setOrderDifference] = useState(0);
  let [open, setOpen] = useState(false);
  let [checkoutOpen, setCheckoutOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [newBalance, setNewBalance] = useState(0);

  const [mergedMealsData, setMergedMealsData] = useState({});
  const [clientSecret, setClientSecret] = useState(null);
  const [amount, setAmount] = useState(0);
  const [firstDD, setFirstDD] = useState("");
  const [disableClicks, setDisableClicks] = useState(false);
  const [deliveryDay, setDeliveryDay] = useState(
    authState.user.deliveryDay || ""
  );
  const [checked, setChecked] = useState(false);
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [streetMessage, setStreetMessage] = useState("");
  const [street, setStreet] = useState("");
  const [add1Message, setAdd1Message] = useState("");
  const [add2Message, setAdd2Message] = useState("");
  const [cityMessage, setCityMessage] = useState("");
  const [rangeStart, setRangeStart] = useState("");

  const handleRangeStart = useCallback((startDate) => {
    setRangeStart(startDate);
    setmostRecentMonday(startDate);
  }, []); // No dependencies, so the function reference remains stable

  const handleChange = (e) => {
    let { name, value } = e.target;

    e.target.nextSibling.style.display = "none";

    // setNewFullOrderData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    switch (name) {
      case "add1":
        setAdd1(value);
        break;
      case "add2":
        setAdd2(value);
        break;
      case "city":
        setCity(value);
        break;

      case "street":
        setStreet(value);
        break;
    }
  };

  const filters = [
    "family friendly",
    "kid friendly",
    "low carb",
    "vegetarian",
    "pescatarian",
    "calorie smart",
    "classics",
    "guilt free",
    "chicken",
    "meat",
    "gourmet",
    "breakfast",
    "clear filter",
  ];

  const [selectedDate, setSelectedDate] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const buttonsRef = useRef([]);
  const [firstDeliveryDate, setFirstDeliveryDate] = useState(null);
  const [transformedSelections, setTransformedSelections] = useState([]);
  const [newOrderObject, setNewOrderObject] = useState({});

  // const [smallimgs1, setSmallimgs1] = useState(formData.smallimgs1 || []);
  // const [smallimgs2, setSmallimgs2] = useState(formData.smallimgs2 || []);
  // const [smallimgs3, setSmallimgs3] = useState(formData.smallimgs3 || []);
  // const [smallimgs4, setSmallimgs4] = useState(formData.smallimgs4 || []);
  const [smallimgs, setSmallimgs] = useState([]);

  const counts = {};
  //const { state } = location;

  const navigate = useNavigate();
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const checkoutDialogRef = useRef(null);
  const updateDialogRef = useRef(null);
  const toggleDialog = (id) => {
    let dialogRef;
    switch (id) {
      case "checkoutModal":
        dialogRef = checkoutDialogRef;
        setCheckoutModalOpen(!isCheckoutModalOpen);
        break;
      case "paymentModal":
        dialogRef = updateDialogRef;
        setPaymentModalOpen(!isPaymentModalOpen);
        break;
      default:
        return;
    }
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.classList.add("closing")
      : dialogRef.current.classList.remove("closing");
    dialogRef.current.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();
    if (dialogRef.current.hasAttribute("open")) {
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setCredit(authState.user.credit);
  }, []);

  const handleClick = async (e) => {
    for (const [weekstarting, meals] of Object.entries(currentSelections)) {
      //console.log(totalCount);
      if (totalCount < 3 && totalCount !== 0) {
        handleOpen();
        return false;
      }
    }

    const transformedSelectionsTemp = transformSelectionsToMealsByStartDate(
      currentSelections,
      orderCost
    );
    setTransformedSelections(transformedSelectionsTemp);
    // Console log the transformed selections

    if (amount < 1) {
      toggleDialog("paymentModal");
      const updateUser = async (userId, field, value) => {
        try {
          const response = await axios.patch("/api/auth/updateUser", {
            userId,
            field,
            value,
          });
          // console.log("User updated:", response.data);
        } catch (error) {
          console.error("Error updating user:", error);
        }
      };
      if (credit >= 0) {
        console.log("credit", credit);
        updateUser(userId, "credit", credit);
      }
      // console.log("currentSelections", currentSelections);
      // console.log("transformedSelections", transformedSelections);
      let date = new Date(calculateUpcomingDay(targetDay).upcomingDate);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
      let day = date.getDate().toString().padStart(2, "0");
      const deliveryDate = `${year}-${month}-${day}`;
      try {
        const mealsByStartDate = Object.keys(currentSelections).map((date) => ({
          weekstarting: date, // Use the date as weekstarting
          deliveryDay: authState.user.deliveryDay,
          deliveryDate: deliveryDate,
          deliverTimeSlot: authState.user.deliverTimeSlot,
          servings: people,
          mealSelections: currentSelections[date], // Use the array of meal selections for this date
          ordertotal: orderTotal,
        }));

        const response = await axios
          .put(`/api/auth/updateUserMealSelections/${userId}`, {
            mealsByStartDate,
          })
          .then((response) => {
            setUpdateMessage(response.data.message);
            setLoading(false);
          });
      } catch (error) {
        console.error(`Error updating meal selections:`, error);
        setLoading(false); // Ensure loading state is turned off on error as well
      }
    } else {
      toggleDialog("checkoutModal");
      setLoading(true);
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!rangeStart) {
      return;
    }
    const fetchMeals = async () => {
      setLoading(true);
      //console.log("rangeStart", rangeStart);
      try {
        const response = await axios
          .get(`/api/auth/newMeals`, {
            params: {
              dateUsed: rangeStart,
            },
          })
          .then((res) => {
            const mealsData = res.data.mergedResults;
            const prefLength = preferences.length;

            // console.log("Meals Data:", mealsData); // Debugging log

            switch (prefLength) {
              case 1:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  a.mealtype[0].includes(preferences[0])
                    ? -1
                    : 1
                );
                break;
              case 2:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]))
                    ? -1
                    : 1
                );
                break;
              case 3:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]))
                    ? -1
                    : 1
                );
                break;
              case 4:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]) ||
                    a.mealtype[0].includes(preferences[3]))
                    ? -1
                    : 1
                );
                break;
              case 5:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]) ||
                    a.mealtype[0].includes(preferences[3]) ||
                    a.mealtype[0].includes(preferences[4]))
                    ? -1
                    : 1
                );
                break;
              default:
                break;
            }

            setMealsList(mealsData);
            setFilteredMealsList(mealsData);
          });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    const fetchOrders = async () => {
      try {
        const response = await axios.get("/api/auth/myorders", {
          params: { id: userId, dateUsed: rangeStart },
        });

        const allOrderData = response.data.allOrderData;
        // console.log("All Order Data:", allOrderData);

        if (allOrderData.length === 0) {
          setDisableClicks(false);
        } else {
          const firstMeal = allOrderData[0].mealsByStartDate[0];
          // console.log("First Meal:", firstMeal.deliveryDate);
          var firstDeliveryDate = firstMeal.deliveryDate;
          setFirstDD(firstMeal.deliveryDate);

          // Parse the date in DD/MM/YYYY format
          const [day, month, year] = firstDeliveryDate.split("/");
          const deliveryDate = new Date(`${year}-${month}-${day}`);

          const now = new Date();

          // Check if mostRecentMonday is the current week
          const startOfCurrentWeek = new Date(now);
          startOfCurrentWeek.setHours(0, 0, 0, 0);
          startOfCurrentWeek.setDate(now.getDate() - now.getDay() + 1); // Set to Monday of the current week
          //console.log("startOfCurrentWeek", startOfCurrentWeek);
          const startOfMostRecentMonday = new Date(mostRecentMonday);
          startOfMostRecentMonday.setHours(0, 0, 0, 0);

          const isCurrentWeek =
            startOfMostRecentMonday.getTime() === startOfCurrentWeek.getTime();

          // Calculate the day difference
          const timeDiff = deliveryDate.getTime() - now.getTime();
          const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

          // Log the time difference for debugging
          // console.log("days difference:", daysDiff);

          // Check if mostRecentMonday is the current week and daysDiff <= 2
          //console.log("CurrentWeek", mostRecentMonday);
          // if (isCurrentWeek && daysDiff <= 2) {
          //   // console.log("disable clicks");
          //   setDisableClicks(false);
          // }
        }
        const orderCosts = {};
        let totalOrderCost = 0;

        // console.log("allOrderData:", allOrderData); // Debugging log

        allOrderData.forEach((order) => {
          order.mealsByStartDate.forEach((meal) => {
            const weekstarting = meal.weekstarting;
            orderCosts[weekstarting] = meal.ordertotal;
            //
            totalOrderCost += meal.ordertotal;
            setOrderTotal(totalOrderCost);
          });
        });

        setCurrentSelections(() => {
          const selections = {};
          allOrderData.forEach((order) => {
            order.mealsByStartDate.forEach((meal) => {
              if (!selections[meal.weekstarting]) {
                selections[meal.weekstarting] = [];
              }
              selections[meal.weekstarting] = meal.mealSelections.map(
                (item) => ({
                  meal: item.meal,
                  count: item.count,
                })
              );
            });
          });
          //console.log("CSelections:", currentSelections);
          return selections;
        });
        //console.log("mostRecentMonday", mostRecentMonday);
        setmostRecentMonday(mostRecentMonday);
        setValues(response.data.mealSelections);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMeals();
    fetchOrders();
    setLoading(false);
  }, [rangeStart]);

  useEffect(() => {
    // console.log("currentSelections", currentSelections);
    // console.log("MealsList", MealsList);

    if (MealsList && Array.isArray(MealsList)) {
      // Create an empty array to hold the small images
      const smallimgs = [];

      // Iterate through the keys of currentSelections
      Object.keys(currentSelections).forEach((mostRecentMonday) => {
        const selections = currentSelections[mostRecentMonday];

        if (Array.isArray(selections)) {
          selections.forEach((selection) => {
            // console.log("Processing selection:", selection);
            const meal = MealsList.find((meal) => meal._id === selection.meal);
            if (meal) {
              // console.log("Found meal:", meal);
              if (meal.image) {
                smallimgs.push(meal.image);
              } else {
                console.log("Meal does not have an image:", meal);
              }
              // } else {
              //   console.log("Meal not found for selection:", selection);
            }
          });
        } else {
          console.error(
            `Selections for date ${mostRecentMonday} is not an array`
          );
        }
      });

      // console.log("smallimgs", smallimgs);
      setSmallimgs(smallimgs);
    } else {
      console.error("MealsList is not an array");
    }
  }, [currentSelections, MealsList]);

  const addHours = (date, hours) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);

    return newDate;
  };

  const getNextDay = (weekStarting) => {
    const dayOfWeekMapping = { sunday: 0, wednesday: 3, friday: 5 };
    const targetDayOfWeek = dayOfWeekMapping[deliveryDay.toLowerCase()];

    const startOfWeek = new Date(weekStarting);
    const currentDay = startOfWeek.getDay();

    // Calculate days until the next target day
    let daysUntilNextDay = targetDayOfWeek - currentDay;
    if (daysUntilNextDay <= 0) {
      daysUntilNextDay += 7; // Ensure it moves to the next week if today is the target day
    }

    const nextDay = new Date(startOfWeek);
    nextDay.setDate(startOfWeek.getDate() + daysUntilNextDay);

    // console.log(
    //   `Week starting: ${weekStarting}, Current day: ${currentDay}, Target day: ${targetDayOfWeek}, Days until next: ${daysUntilNextDay}`
    // );
    // console.log(`Next day (before adjustments): ${nextDay}`);

    // Adjust for the year transition from December to January
    if (nextDay.getMonth() === 0 && startOfWeek.getMonth() === 11) {
      nextDay.setFullYear(startOfWeek.getFullYear() + 1);
    }

    return nextDay;
  };

  useEffect(() => {
    if (mostRecentMonday) {
      // console.log("mostRecentMonday", mostRecentMonday);
      const dateSelections = currentSelections[mostRecentMonday] || [];
      // console.log("dateSelections", dateSelections);
      const totalCount = dateSelections.reduce(
        (sum, selection) => sum + selection.count,
        0
      );

      setTotalCount(totalCount);
      //console.log(`Total count for ${mostRecentMonday}: ${totalCount}`);
    }
  }, [mostRecentMonday, currentSelections]);

  useEffect(() => {
    // console.log("currentSelections", currentSelections[mostRecentMonday]);
    // const originalCost = originalOrderCost[mostRecentMonday] ?? 0;
    // setNewBalance(balance - (originalCost - orderCost[mostRecentMonday]));
    // console.log("Originalordercost", originalCost, balance, newBalance);
    // setAmount((newBalance - balance) * 100);
    //console.log("amount", amount);
  }, [
    currentSelections,
    orderCost,
    newBalance,
    originalOrderCost,
    amount,
    balance,
  ]);

  const handleGetRequest = async (dateUsed, index) => {
    //console.log("dateUsedx", dateUsed);
    setSmallimgs([]);
    setLoading(true);
    setmostRecentMonday(dateUsed);
    setDisableClicks(false);
    // console.log(firstDD);

    if (index === 0) {
      const [day, month, year] = firstDD.split("/");
      const deliveryDate = new Date(`${year}-${month}-${day}`);

      const now = new Date();

      const timeDiff = deliveryDate.getTime() - now.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: { dateUsed: dateUsed },
      });

      if (response) {
        setErrorMessage("");
        const mealsData = response.data.mergedResults;
        setMealsList(mealsData);
        setFilteredMealsList(mealsData);
        // console.log("Originalordercost2", originalOrderCost);
        const ordersResponse = await axios.get("/api/auth/myorders", {
          params: { id: userId, dateUsed: dateUsed },
        });
      }
    } catch (error) {
      setErrorMessage(
        "We are currently creating a delicious menu for you to choose from. Please check back later."
      );
      console.log("Error fetching meals or orders:", error);
    }
    //}
    setLoading(false);
  };

  const handleSelectionAdd = (event, mealId, mostRecentMonday) => {
    event.stopPropagation();
    //   console.log(event, mealId, mostRecentMonday);
    const totalCount = (currentSelections[mostRecentMonday] || []).reduce(
      (sum, item) => sum + item.count,
      0
    );
    //  console.log("Total count:", totalCount);

    if (totalCount < nmeals) {
      setCurrentSelections((prevSelections) => {
        const selectionsForDate = prevSelections[mostRecentMonday] || [];
        const existingSelection = selectionsForDate.find(
          (selection) => selection.meal === mealId
        );
        let updatedSelections;
        if (existingSelection) {
          updatedSelections = selectionsForDate.map((selection) =>
            selection.meal === mealId
              ? {
                  ...selection,
                  count: selection.count + 1,
                }
              : selection
          );
        } else {
          updatedSelections = [
            ...selectionsForDate,
            { meal: mealId, count: 1 },
          ];
        }
        const newOrderTotal = calculateOrderTotal(updatedSelections);
        setOrderCost((prevCosts) => ({
          ...prevCosts,
          [mostRecentMonday]: newOrderTotal,
        }));
        setBalance(newOrderTotal);
        console.log("newBalance", balance);
        return { ...prevSelections, [mostRecentMonday]: updatedSelections };
      });

      if (mealsCount < nmeals) {
        setMealsCount((prevState) => prevState + 1);
        let counter = event.currentTarget
          .closest(".mealCard")
          .querySelector(".counter");
        let button = event.currentTarget
          .closest(".mealCard")
          .querySelector("button");
        counter.innerText = parseInt(counter.innerText) + 1;
        button.classList.remove("show");
        button.classList.add("hide");

        event.currentTarget.closest(".mealCard").classList.add("addactive");
      }
    }
    //console.log(currentSelections);
  };

  const calculateOrderTotal = (selections) => {
    let total = 0;
    selections.forEach((selection) => {
      const meal = MealsList.find((meal) => meal._id === selection.meal);
      const isGourmet = meal && meal.mealtype.includes("gourmet");
      total +=
        selection.count * (pricepermeal + (isGourmet ? gourmetPrice : 0));
    });
    let totalOrder = people * total;

    //setNewBalance(totalOrder);
    setCredit(orderTotal - totalOrder);
    const totalAmount = totalOrder - orderTotal;
    console.log("totalAmount", totalAmount);
    if (credit > 0 && totalAmount > 0) {
      totalAmount = totalAmount - credit;
    }
    setBalance(totalAmount);
    setAmount(totalAmount * 100);
    // console.log("amount", amount);
    // console.log("orderTotal", orderTotal);
    console.log("credit", credit);
    // console.log("totalOrder", newBalance);
    // setBalance(totalOrder);
    // setOrderTotal(totalOrder);
    return people * total;
  };

  useEffect(() => {
    calculateOrderTotal(currentSelections[mostRecentMonday] || []);
    //console.log("OrderCost", orderCost);
  }, [currentSelections]);

  const removeEmptyArrays = (selections) => {
    for (const [key, value] of Object.entries(selections)) {
      if (Array.isArray(value) && value.length === 0) {
        delete selections[key];
      }
    }
    return selections;
  };

  const handleSelectionRemove = (event, mealId, mostRecentMonday) => {
    event.stopPropagation();
    // console.log(mealId, mostRecentMonday);

    setCurrentSelections((prevSelections) => {
      const selectionsForDate = prevSelections[mostRecentMonday] || [];
      const updatedSelections = selectionsForDate
        .map((selection) =>
          selection.meal === mealId
            ? {
                ...selection,
                count: selection.count - 1,
              }
            : selection
        )
        .filter((selection) => selection.count > 0);
      const newOrderTotal = calculateOrderTotal(updatedSelections);
      setOrderCost((prevCosts) => ({
        ...prevCosts,
        [mostRecentMonday]: newOrderTotal,
      }));

      const newSelections = {
        ...prevSelections,
        [mostRecentMonday]: updatedSelections,
      };
      //return removeEmptyArrays(newSelections);
      // console.log("newSelections", newSelections);

      return newSelections;
    });

    if (mealsCount > 0) {
      setMealsCount((prevState) => prevState - 1);
      let counter = event.currentTarget
        .closest(".mealCard")
        .querySelector(".counter");
      let button = event.currentTarget
        .closest(".mealCard")
        .querySelector("button");
      counter.innerText = parseInt(counter.innerText) - 1;
      if (parseInt(counter.innerText) === 0) {
        button.classList.remove("hide");
        button.classList.add("show");
        event.currentTarget.closest(".mealCard").classList.remove("addactive");
      }
    }
  };

  const handleFilter = (selectedCategory) => {
    if (selectedCategory === "clear filter") {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(selectedCategory)) {
        let filters = selectedFilters.filter((el) => el !== selectedCategory);
        setSelectedFilters(filters);
      } else {
        setSelectedFilters([...selectedFilters, selectedCategory]);
      }
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters, filteredMealsList]);

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = filteredMealsList.filter((item) =>
          item.mealtype.some((type) => type.includes(selectedCategory))
        );
        // console.log(temp);
        return temp;
      });

      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));

        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
    } else {
      setMealsList(filteredMealsList);
    }
  };

  const containsGourmet = (mealtype) => {
    if (Array.isArray(mealtype) && mealtype.length > 0) {
      // Split the first element of the array by commas and trim whitespace
      const typesArray = mealtype[0].split(",").map((item) => item.trim());
      // Check if 'gourmet' is in the resulting array
      return typesArray.includes("gourmet");
    }
    return false;
  };

  useEffect(() => {
    document.title = "My Meals";
    // setSelectedDate(state);

    setTimeout(() => {
      const buttons = document.querySelectorAll(".addMeal");
      buttonsRef.current = Array.from(buttons);
      // console.log("melass count", totalCount, meals);

      if (buttons && buttons.length > 0) {
        if (totalCount === nmeals) {
          buttons.forEach((button) => {
            if (button) {
              button.classList.add("disabled");
            }
          });
        } else {
          buttons.forEach((button) => {
            if (button) {
              button.classList.remove("disabled");
            }
          });
        }
      }
    }, 250);
  }, [totalCount, nmeals, MealsList, selectedDate]);

  // Fetch detailed meal data
  useEffect(() => {
    const fetchMealDetails = async () => {
      const mealIds = Object.values(currentSelections)
        .flat()
        .map((selection) => selection.meal);
      try {
        const response = await axios.post("/api/auth/getMealDetails", {
          mealIds,
        });
        const mealsData = response.data;
        // Merge meal details with current selections
        const mergedData = {};
        Object.entries(currentSelections).forEach(
          ([weekstarting, selections]) => {
            mergedData[weekstarting] = selections.map((selection) => {
              const mealDetail = mealsData.find(
                (meal) => meal._id === selection.meal
              );
              return { ...selection, ...mealDetail };
            });
          }
        );
        setMergedMealsData(mergedData);
        // console.log(mergedData, currentDeliveryDate.toLocaleDateString());
      } catch (error) {
        console.error("Error fetching meal details:", error);
      }
    };
    fetchMealDetails();
  }, [currentSelections]);
  let currentDeliveryDate = getNextDay(authState.user.deliveryDay); // Get the first delivery date

  const handlePayment = async (event) => {
    event.stopPropagation();
    if (!checked) {
      if (!add1 || !add2 || !city || !street) {
        if (!add1) {
          document.querySelector(".add1").style.display = "block";
          setAdd1Message("Please enter address");
        }

        if (!add2) {
          document.querySelector(".add2").style.display = "block";
          setAdd2Message("Please enter address");
        }
        if (!city) {
          document.querySelector(".city").style.display = "block";
          setCityMessage("Please enter city");
        }

        if (!street) {
          document.querySelector(".street").style.display = "block";
          setStreetMessage("Please enter street");
        }
        return;
      }
    }

    let payload;
    if (!checked) {
      payload = {
        amount: newBalance - balance * 100,
        items: [
          {
            name: "Meals",
            amount: newBalance - balance * 100,
            description: `Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: add1,
          addressLine2: add2,
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          street: street,
          town: city,
          phone_number: authState.user.contact,
          country: "UAE",
          email: authState.user.email,
        },
        customer: {
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          email: authState.user.email,
        },
      };
    } else {
      payload = {
        amount: amount,
        items: [
          {
            name: "Meals",
            amount: amount,
            description: ` Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: authState.user.addressLine1,
          addressLine2: authState.user.addressLine2,
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          street: authState.user.street,
          town: authState.user.town,
          phone_number: authState.user.contact,
          country: "UAE",
          email: authState.user.email,
        },
        customer: {
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          email: authState.user.email,
        },
      };
    }

    //console.log(`payload`, payload);

    try {
      const payResponse = await axios.post("/api/auth/payment", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const orderId = payResponse.data.payment_keys[0].order_id;
      //console.log("Extracted order_id:", orderId);
      setNewOrderObject(
        createOrderObject(orderId, authState.user._id, transformedSelections)
      );
      // console.log("New order object:", transformedSelections);
      //const updatedOrderData = { ...neworderData, paymentId: orderId, userId: authState.user._id };
      // console.log("Updated order data:", updatedOrderData);
      // console.log("client secret", payResponse.data.client_secret);
      // Set client secret and update order data
      setClientSecret(payResponse.data.client_secret);
      // console.log("client secret2:", clientSecret);

      // if (clientSecret) {
      //   window.location.href = `https://uae.paymob.com/unifiedcheckout/?publicKey=are_pk_test_IUUNrDEOL7fiDIE9mHncauf607Epi5Q6&clientSecret=${clientSecret}`;
      // }

      //setFullOrderData(updatedOrderData); // Update the state with the new order data
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
      }
    }
  };
  useEffect(() => {
    if (newOrderObject.paymentId && clientSecret) {
      //console.log("Full order data2:", newOrderObject);
      const sendDataToDatabase = async () => {
        try {
          const response = await axios.post(
            "/api/auth/createTempOrder",
            newOrderObject,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          //console.log("Data sent to database:", response.data);

          // Redirect to payment page
          window.location.href = `https://uae.paymob.com/unifiedcheckout/?publicKey=are_pk_test_IUUNrDEOL7fiDIE9mHncauf607Epi5Q6&clientSecret=${clientSecret}`;
        } catch (error) {
          console.error("Error sending data to database:", error);
        }
      };

      sendDataToDatabase();
    }
  }, [newOrderObject, clientSecret]);

  useEffect(() => {
    //console.log("currentSelections", currentSelections);
  }, [currentSelections]);

  const createOrderObject = (paymentId, userId, transformedSelections) => {
    //console.log("transformedSelections", transformedSelections);
    if (!Array.isArray(transformedSelections)) {
      throw new Error("transformedSelections must be an array");
    }
    return {
      paymentId,
      userId,
      total: transformedSelections.reduce(
        (sum, selection) => sum + selection.ordertotal,
        0
      ),
      mealsByStartDate: transformedSelections.map((selection) => ({
        weekstarting: selection.weekstarting,
        deliveryDay: selection.deliveryDay,
        deliveryDate: selection.deliveryDate,
        deliverTimeSlot: selection.deliverTimeSlot,
        servings: selection.servings,
        mealSelections: selection.mealSelections,
        driverName: selection.driverName,
        ordertotal: selection.ordertotal,
      })),
      driver: "", // Assuming driver field is empty; update based on your logic
    };
  };

  const transformSelectionsToMealsByStartDate = (
    currentSelections,
    orderTotal
  ) => {
    const UserdeliveryDay = authState.user.deliveryDay;

    return Object.entries(currentSelections)
      .map(([weekstarting, selections]) => {
        const mealSelections = selections.map((selection) => ({
          meal: selection.meal,
          count: selection.count,
        }));

        return {
          weekstarting,
          deliveryDay: UserdeliveryDay,
          deliveryDate: getNextDay(
            weekstarting,
            UserdeliveryDay,
            firstDD
          ).toLocaleDateString(),
          deliverTimeSlot: authState.user.deliverTimeSlot,
          servings: authState.user.noMeals,
          mealSelections,
          ordertotal: orderTotal[weekstarting] || 0,
          driverName: authState.user.driverName || "",
        };
      })
      .filter((entry) => entry.mealSelections.length > 0); // Filter out entries with empty mealSelections
  };

  const handleCheck = (e) => {
    setChecked((prevChecked) => {
      const newChecked = !prevChecked;
      // console.log("checked", newChecked);

      if (!newChecked) {
        document.querySelector(".billingAddress").style.display = "flex";
      } else {
        document.querySelector(".billingAddress").style.display = "none";
      }

      return newChecked;
    });
  };

  const addMeals = (event) => {
    event.stopPropagation();
    setNmeals((prevState) => prevState + 1);
    // meals = meals + 1;
    // console.log("meals", meals);
    // setNomeals((prevState) => prevState + 1);
    // setMealsCount((prevState) => prevState + 1);
    // setTotalCount((prevState) => prevState + 1);
    // setNmeals((prevState) => prevState + 1);
  };

  const reduceMeals = () => {
    setNmeals((prevState) => prevState - 1);
    // meals = meals - 1;
    // console.log("meals", meals); // setNomeals((prevState) => prevState - 1);
    // setMealsCount((prevState) => prevState - 1); // setTotalCount((prevState) => prevState - 1);   // setNmeals((prevState) => prevState - 1);
  };

  const emptySlots = nmeals - smallimgs.length;
  // console.log("currentSelections", currentSelections[mostRecentMonday]);
  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
          <div className="userData container ">
            <div className="userDashboardAdmin">
              <div className="headerSection">
                <h1>Select Meals</h1>
                <h2>Change or select your Meals</h2>
                <Link to={"/dashboard"}>
                  <button className="btd">Back to dashboard</button>
                </Link>
              </div>

              <div className="mealsList">
                <div className="mealImages">
                  <ul className="chosenmeals">
                    {smallimgs.map((img, index) => (
                      <li className="chosenmeal" key={index} data-index={index}>
                        <img src={`/images/meals/${img}`} alt={img} />
                      </li>
                    ))}
                    {Array.from({ length: emptySlots }, (_, index) => (
                      <li
                        className="chosenmeal"
                        key={smallimgs.length + index}
                      ></li>
                    ))}
                  </ul>
                  <div className="selectedTextDiv">
                    <p className="selectedText">
                      You have selected {totalCount} meals
                    </p>

                    {nmeals < 5 ? (
                      <p className="addmeals" onClick={addMeals}>
                        Add meal
                      </p>
                    ) : (
                      ""
                    )}
                    {/* {nmeals > 3 ? <p onClick={reduceMeals}>Remove meal</p> : ""} */}
                    {/* <p>{noMeals}</p> */}
                  </div>
                  <button className="saveMeals" onClick={handleClick}>
                    Save Meals
                  </button>
                </div>
                <div className="datesContainer">
                  <WeeklyDateRanges
                    onRequest={handleGetRequest}
                    onRangeStart={handleRangeStart}
                  />
                </div>
                <div className="filterContainer">
                  <ul className="filters">
                    {filters.map((category, idx) => (
                      <li
                        className={`filterbutton ${category.replace(
                          / /g,
                          ""
                        )} ${
                          selectedFilters?.includes(category) ? "active" : ""
                        } `}
                        key={`filters-${idx}`}
                        onClick={() => handleFilter(category)}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mealList">
                  {loading ? (
                    <Loading />
                  ) : errorMessage ? (
                    <div className="error">
                      <p>{errorMessage}</p>
                    </div>
                  ) : (
                    MealsList.map((meal, index) => {
                      const baseName = meal.image.split(".")[0]; // Extract base name from image file name
                      const ext = meal.image.split(".")[1]; // Extract extension from image file name

                      return (
                        <div
                          key={meal._id}
                          data-date={meal.weekstarting}
                          id={meal._id}
                          className={`mealCard ${
                            (currentSelections[mostRecentMonday] || []).some(
                              (selection) => selection.meal === meal._id
                            )
                              ? "addactive"
                              : ""
                          } ${
                            containsGourmet(meal.mealtype) ? "gourmet" : ""
                          } ${activeIndex === index ? "expanded" : ""}`}
                          onClick={() => {
                            setSelectedMeal(meal);
                          }}
                        >
                          <div className="imgContainer">
                            <img
                              src={`/images/meals/${baseName}-x.${ext}`} // Default to large image
                              alt={meal.title}
                            />
                          </div>
                          <div className="contentContainer">
                            <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                            </div>
                            <div className="mealtypes">
                              <ul>
                                {meal.mealtype
                                  .toString()
                                  .split(",")
                                  .map((type, index) => (
                                    <li
                                      key={index}
                                      className={type.replace(/ /g, "")}
                                    >
                                      {type}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div
                              className="addRemove"
                              style={{
                                pointerEvents: disableClicks ? "none" : "auto",
                                opacity: disableClicks ? 0.5 : 1,
                              }}
                            >
                              <button
                                ref={(el) => (buttonsRef.current[index] = el)}
                                onClick={(e) =>
                                  handleSelectionAdd(
                                    e,
                                    meal._id,
                                    mostRecentMonday
                                  )
                                }
                                className={`addMeal ${
                                  (
                                    currentSelections[mostRecentMonday] || []
                                  ).find(
                                    (selection) =>
                                      selection.meal === meal._id &&
                                      selection.count > 0
                                  )
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                Add Recipe
                              </button>
                              <p
                                onClick={(e) =>
                                  handleSelectionRemove(
                                    e,
                                    meal._id,
                                    mostRecentMonday
                                  )
                                }
                              >
                                -
                              </p>
                              <p className="counter">
                                {(
                                  currentSelections[mostRecentMonday] || []
                                ).find(
                                  (selection) => selection.meal === meal._id
                                )?.count || 0}
                              </p>
                              <p
                                onClick={(e) =>
                                  handleSelectionAdd(
                                    e,
                                    meal._id,
                                    mostRecentMonday
                                  )
                                }
                              >
                                +
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedMeal && (
        <MealModal meal={selectedMeal} onClose={() => setSelectedMeal(null)} />
      )}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className="modalTop">
          <span>Please check your selections</span>
          <button className="close">X</button>
        </div>

        <div className="modalBody">
          <div className="modalWarning">
            <p>{`Please select ${nmeals} meals for each date you would like meals delivered on`}</p>
          </div>
        </div>
      </Modal>

      <dialog className="filter" ref={checkoutDialogRef} id="checkoutModal">
        <div className="modalTop">
          <span>Checkout</span>
          <button
            className="close"
            onClick={() => {
              toggleDialog("checkoutModal");
              setLoading(false);
            }}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          <div>
            <p>Checkout summary</p>
          </div>
          <div className="mealList">
            {Object.entries(mergedMealsData).map(([weekstarting, meals]) => (
              <div className="summaryMeals" key={weekstarting}>
                <h3>Week Starting: {weekstarting}</h3>

                {meals.map((meal, index) => {
                  const baseName = meal.image.split(".")[0]; // Extract base name from image file name
                  const ext = meal.image.split(".")[1]; // Extract extension from image file name

                  return (
                    <div className="mealCard" key={index}>
                      <div className="imgContainer">
                        <img
                          src={`/images/meals/${baseName}-x.${ext}`}
                          alt={meal.title}
                          width="50"
                          height="50"
                        />
                      </div>
                      <div className="contentContainer">
                        <div className="mealDetails">
                          <h4>{meal.title}</h4>
                          <p>{meal.subtitle}</p>
                          <p>{meal.desc}</p>
                        </div>
                        <div className="mealsBottom mealsCount">
                          <p>{meal.count} meals selected</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div className="summaryDiv ">
            <h2>Price Details</h2>
            <div className="boxesData">
              <div className="boxesPrice">
                <p>Price per serving: {pricepermeal}AED</p>
                <hr></hr>

                {/* <p>
                  {people * nomeals} Servings
                  <span>{people * nomeals * pricepermeal}AED</span>
                </p> */}
                {/* {totalGourmetCount > 0 && (
                              <p>
                                Extra cost of {totalGourmetCount} gourmet meals:{" "}
                                <span>{totalGourmetCost}AED</span>
                              </p>
                            )} */}
                {/* <p>
                  Delivery <span>Free</span>
                </p> */}
                <hr></hr>
                <p className="totalPrice">
                  Amount to pay <span>{balance}AED</span>
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="containerForm payment">
            <div className="right">
              <form>
                <div className="paymentForm">
                  <h3>Billing address</h3>
                  <div className="checkboxAddress">
                    <input
                      type="checkbox"
                      id="sameAddress"
                      name="sameAddress"
                      onChange={handleCheck}
                    />
                    <label htmlFor="sameAddress">
                      Same as delivery address
                    </label>
                  </div>
                  <div className="billingAddress">
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add1"
                        name="add1"
                        placeholder="Enter Address Line 1"
                        value={add1}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add1">
                        {add1Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add2"
                        name="add2"
                        placeholder="Enter Address Line 2"
                        value={add2}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add2">
                        {add2Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="street"
                        name="street"
                        placeholder="Enter Street Name"
                        value={street}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror street">
                        {streetMessage}
                      </div>
                    </div>

                    <div className="errorContainer">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Enter City"
                        value={city}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror city">
                        {cityMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button type="submit" onClick={handlePayment}>
            Pay Now
          </button>
        </div>
      </dialog>
      <dialog className="filter" ref={updateDialogRef} id="paymentModal">
        <div className="modalTop">
          <span>Processing meals</span>
          <button
            className="close"
            onClick={() => (toggleDialog, navigate("/dashboard"))}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{updateMessage}</p>
          )}
        </div>
      </dialog>
    </>
  );
};

export default MyMeals;
