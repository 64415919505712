import React, { createContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Create the context
export const FormContext = createContext();

// Create the provider component
export const FormProvider = ({ children }) => {
  // Load initial data from local storage or use default values
  const initialFormData = JSON.parse(localStorage.getItem("formData")) || {
    people: 0,
    nmeals: 0,
    total: 0,
    preferences: [],
    mealSelections: [],
    mealsCount: [],
    email: "",
    password: "",
    fname: "",
    lname: "",
    contact: "",
    addressLine1: "",
    addressLine2: "",
    street: "",
    town: "Dubai",
    area: "",
    deliveryDay: "",
    deliverTimeSlot: "",
    specialInstructions: "",
    billingadd1: "",
    billingadd2: "",
    billingcity: "",
    billingarea: "",
    billingstreet: "",
    smImages: [],
    smallimgs: [],
    smallimgs1: [],
    smallimgs2: [],
    smallimgs3: [],
    smallimgs4: [],
    extras: [
      {
        extra: "",
        price: "",
        quantity: "",
      },
    ],
    initialPayment: 0,
    balance: 0,
    paymentHistory: [{ amount: 0 }],
    mealsByStartDate: {},
    additionalOrderData: {},
    currentStep: 0, // Add currentStep to the form data
  };

  const [formData, setFormData] = useState(initialFormData);

  // Save form data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const stepLabels = [
    "Boxes",
    "Preferences",
    "Meals",
    "Sign Up",
    "Delivery",
    "Summary",
  ];
  const stepPaths = [
    "/boxes",
    "/preferences",
    "/meals",
    "/signup",
    "/delivery",
    "/summary",
  ];
  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      <ul className="signupNav">
        {/* <li>{formData.currentStep}</li> */}
        {stepLabels.map((label, index) => (
          <li key={label}>
            {index <= formData.currentStep ? (
              <Link to={stepPaths[index]}>{label}</Link>
            ) : (
              <span className="disabled">{label}</span>
            )}
          </li>
        ))}
      </ul>
      {children}
    </FormContext.Provider>
  );
};
