import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/merged.css";
import NextDaysComponent from "./deliverydays";
import { FormContext } from "./FormContext";

export const Deliver = () => {
  const { formData, setFormData } = useContext(FormContext);
  const [fname, setFname] = useState(formData.fname || "");
  const [lname, setLname] = useState(formData.lname || "");
  const [contact, setContact] = useState(formData.contact || "");
  const [addressLine1, setAddress1] = useState(formData.addressLine1 || "");
  const [addressLine2, setAddress2] = useState(formData.addressLine2 || "");
  const [street, setStreet] = useState(formData.street || "");
  const [area, setArea] = useState(formData.area || "");
  const [town, setTown] = useState(formData.town || "");
  const [specialInstructions, setSpinst] = useState(
    formData.specialInstructions || ""
  );

  const [delday, setDelday] = useState(formData.deliveryDay || "Sunday");
  const [delslot, setDelslot] = useState(formData.deliverTimeSlot || "1");
  let [isOpen, setIsOpen] = useState(false);
  const [tdate, setTdate] = useState("");
  const [slotText, setSlotText] = useState("6-9am");
  const navigate = useNavigate();

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      currentStep: 4,
    }));
  }, []);
  const areas = [
    "Abu Dhabi",
    "Ajmān",
    "Al Ain",
    "Al Awdah",
    "Al Fahlayn",
    "Al Fulayyah",
    "Al Fara'",
    "Al Ghabah",
    "Al Ghabam",
    "Al Ghashbah",
    "Al Hamraniyah",
    "Al Hamriyah",
    "Al Haybah",
    "Al Hayl",
    "Al Hayr",
    "Al Hayrah",
    "Al Hulaylah",
    "Al Jaddah",
    "Al Khari",
    "Al Khashfah",
    "Al Mahamm",
    "Al Masafirah",
    "Al Mataf",
    "Al Mu'ammarah",
    "Al Naslah",
    "Al Qir",
    "Al Quwayz",
    "Al Usayli",
    "Al Yahar",
    "Ar Rafa'ah",
    "Arthaban",
    "Athabat",
    "Ash Sha'm",
    "As Sur",
    "Awanat, Ras al-Khaimah",
    "Baqal",
    "Bidiyah",
    "Daftah",
    "Dhadna",
    "Dibba Al-Fujairah",
    "Dibba Al-Hisn",
    "Dubai",
    "Fujairah",
    "Kalba",
    "Kawr Fakkān",
    "Mīnā' Jabal 'Alī",
    "Mīnā' Şaqr",
    "Mīnā' Zāyid",
    "Ras al-Khaimah",
    "Umm al-Qaiwain",
    "Quṭūf",
    "Ruwais",
    "Sharjah",
    "Sila",
  ];

  const ref = useRef();

  const [fnerrorMessage, setfnerrorMessage] = useState("");
  const [lnerrorMessage, setlnerrorMessage] = useState("");
  const [cyerrorMessage, setcyerrorMessage] = useState("");
  const [cnerrorMessage, setcnerrorMessage] = useState("");
  const [a1errorMessage, seta1errorMessage] = useState("");
  const [a2errorMessage, seta2errorMessage] = useState("");
  const [sterrorMessage, setsterrorMessage] = useState("");
  const [arerrorMessage, setareerrorMessage] = useState("");
  // console.log(formData)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !fname ||
      !lname ||
      !contact ||
      !addressLine1 ||
      !street ||
      !town ||
      !area
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (!fname) {
        document.querySelector(".fnerror").style.display = "block";
        setfnerrorMessage("Please enter your first name");
      } else {
        document.querySelector(".fnerror").style.display = "none";
        setfnerrorMessage("");
      }

      if (!lname) {
        document.querySelector(".lnerror").style.display = "block";
        setlnerrorMessage("Please enter your last name");
      } else {
        document.querySelector(".lnerror").style.display = "none";
        setlnerrorMessage("");
      }

      if (!contact) {
        document.querySelector(".cnerror").style.display = "block";
        setcnerrorMessage("Please enter a valid number");
      } else {
        document.querySelector(".cnerror").style.display = "none";
        setcnerrorMessage("");
      }

      if (!addressLine1) {
        document.querySelector(".a1error").style.display = "block";
        seta1errorMessage("Please enter your address");
      } else {
        document.querySelector(".a1error").style.display = "none";
        seta1errorMessage("");
      }

      if (!street) {
        document.querySelector(".sterror").style.display = "block";
        setsterrorMessage("Please enter your street");
      } else {
        document.querySelector(".sterror").style.display = "none";
        setsterrorMessage("");
      }

      if (!area) {
        document.querySelector(".arerror").style.display = "block";
        setareerrorMessage("Please enter your area");
      } else {
        document.querySelector(".arerror").style.display = "none";
        setareerrorMessage("");
      }

      if (!town || town === "") {
        document.querySelector(".cyerror").style.display = "block";
        setcyerrorMessage("Please select your town or city");
      } else {
        console.log(town);
        document.querySelector(".cyerror").style.display = "none";
        setcyerrorMessage("");
      }
    } else {
      navigate("/summary");
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const Selectarea = () => {
    return (
      <>
        <select
          onChange={handleChange}
          name="area"
          id="area"
          defaultValue={area}
        >
          <option>Select Area*</option>
          {areas?.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  useEffect(() => {
    switch (delday) {
      case "Monday":
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7));
        setTdate(d.toString().slice(4, 10));

        break;
      case "Tuesday":
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 8 - d.getDay()) % 7 || 7));
        setTdate(d.toString().slice(4, 10));

        break;
      case "Wednesday":
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 9 - d.getDay()) % 7 || 7));
        setTdate(d.toString().slice(4, 10));
        break;
      case "Thursday":
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 10 - d.getDay()) % 7 || 7));
        setTdate(d.toString().slice(4, 10));
        break;
      case "Friday":
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 11 - d.getDay()) % 7 || 7));
        setTdate(d.toString().slice(4, 10));
        break;
    }
  }, [delday]);
  const [selectedDate, setSelectedDate] = useState("");

  const handleDay = (e) => {
    const selectedDay = e.target.getAttribute("value");
    setSelectedDate(calculateDate(e.target.getAttribute("value")));
    setFormData((prevData) => ({
      ...prevData,
      deliveryDay: selectedDay,
    }));

    //const selectedSlot = slotSetter[delslot]; // Assuming slotsetter is an object with slots for each day

    setDelday(selectedDay);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (e.target.nextSibling) {
      e.target.nextSibling.style.display = "none";
    }

    if (name === "contact") {
      value = value.replace(/[^0-9]/g, "");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    switch (name) {
      case "fname":
        setFname(value);
        break;
      case "lname":
        setLname(value);
        break;
      case "contact":
        setContact(value);
        break;
      case "addressLine1":
        setAddress1(value);
        break;
      case "addressLine2":
        setAddress2(value);
        break;
      case "street":
        setStreet(value);
        break;
      case "area":
        setArea(value);
        break;
      case "town":
        setTown(value);

        break;
      case "specialInstructions":
        setSpinst(value);
        break;
    }
    // console.log(formData)
  };

  const handledelSlot = (e) => {
    setDelslot(e.target.getAttribute("value"));

    setSlotText(e.target.innerHTML);

    setFormData((prevData) => ({
      ...prevData,
      deliverTimeSlot: e.target.getAttribute("value"),
    }));
  };

  const filterRef = useRef();
  function toggleDialog(e) {
    e.preventDefault();
    if (!filterRef.current) {
      return;
    }
    filterRef.current.hasAttribute("open")
      ? filterRef.current.classList.add("closing")
      : filterRef.current.classList.remove("closing");
    filterRef.current.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    filterRef.current.hasAttribute("open")
      ? filterRef.current.close()
      : filterRef.current.showModal();
  }

  const updateCharCount = () => {
    var textInput = document.getElementById("specialInstructions");
    var charCount = textInput.value.length;
    document.getElementById("charCount").textContent = charCount;
  };

  const calculateDate = (day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const dayIndex = daysOfWeek.indexOf(day);
    const diff = (dayIndex + 7 - today.getDay()) % 7;
    const resultDate = new Date(today);
    resultDate.setDate(today.getDate() + diff);
    // Format date as YYYY-MM-DD
    const year = resultDate.getFullYear();
    const month = String(resultDate.getMonth() + 1).padStart(2, "0");
    const date = String(resultDate.getDate()).padStart(2, "0");
    return `${date}-${month}-${year}`;
  };

  const parseDate = (dateString) => {
    const [date, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, date);
  };

  const isWithinTwoDays = (day) => {
    const today = new Date();
    console.log("Today:", today);
    const targetDate = parseDate(calculateDate(day));
    console.log("Target date:", targetDate);
    const timeDiff = targetDate - today;
    console.log("Time difference:", timeDiff);
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    console.log("Day difference:", dayDiff);
    return dayDiff <= 2;
  };

  return (
    <div className="container ">
      <h1>Delivery</h1>
      <div className="deliveryForm">
        <div className="left">
          <h2>Where do you want your meals delivered?</h2>
          <form className="deliver">
            <div className="formElements">
              <div className="errorContainer">
                {" "}
                <input
                  type="input"
                  placeholder="First name*"
                  id="fname"
                  name="fname"
                  value={fname}
                  onChange={handleChange}
                ></input>
                <div className="error fnerror inputerror">{fnerrorMessage}</div>
              </div>
              <div className="errorContainer">
                <input
                  type="input"
                  placeholder="Last name*"
                  id="lname"
                  name="lname"
                  value={lname}
                  onChange={handleChange}
                ></input>
                <div className="error lnerror inputerror">{lnerrorMessage}</div>
              </div>
              <div className="errorContainer phoneInput">
                <span>+971</span>
                <input
                  type="phone"
                  prefix="+971"
                  inputMode="numeric"
                  placeholder="Contact Number*"
                  id="contact"
                  name="contact"
                  value={contact}
                  onChange={handleChange}
                ></input>
                <div className="error cnerror inputerror">{cnerrorMessage}</div>
              </div>
              <div className="errorContainer">
                <input
                  type="input"
                  placeholder="Address line 1*"
                  id="address1"
                  name="addressLine1"
                  value={addressLine1}
                  onChange={handleChange}
                ></input>
                <div className="error a1error inputerror">{a1errorMessage}</div>
              </div>
              <div>
                <input
                  type="input"
                  placeholder="Address line 2"
                  id="address2"
                  name="addressLine2"
                  value={addressLine2}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="errorContainer">
                <input
                  type="input"
                  placeholder="Street name*"
                  id="street"
                  name="street"
                  value={street}
                  onChange={handleChange}
                ></input>
                <div className="error sterror inputerror">{sterrorMessage}</div>
              </div>
              <div className="errorContainer">
                <input
                  type="input"
                  placeholder="Dubai*"
                  id="town"
                  name="town"
                  value={town}
                  onChange={handleChange}
                  disabled
                ></input>
                <div className="error cyerror inputerror">{cyerrorMessage}</div>
              </div>
              <div className="errorContainer">
                <Selectarea />
                <div className="error arerror inputerror">{arerrorMessage}</div>
              </div>
            </div>
            <h2>Any special delivery instructions?</h2>
            <textarea
              id="specialInstructions"
              name="specialInstructions"
              value={specialInstructions}
              onInput={updateCharCount}
              onChange={handleChange}
              maxLength="80"
            ></textarea>
          </form>
          <p>
            Characters used: <span id="charCount">0</span>/50
          </p>
        </div>
        <div>
          <div className="wwrapper">
            <h2>When do you want your meals delivered?</h2>
            {/* <p><strong>{delday} {tdate} between {slotText}</strong> <br />If you place your order in the next <span> 10 hours</span></p> 
    <button  onClick={toggleDialog}>Edit</button> */}
            <form className="deliver">
              <div className="deliveryDay">
                <ul>
                  {/* <li  value="Tuesday" className={delday === "Tuesday" ? "active" : "" }  onClick={handleDay}>Tuesday</li> */}
                  <li
                    value="Wednesday"
                    className={`${delday === "Wednesday" ? "active" : ""} ${
                      isWithinTwoDays("Wednesday") ? "disabled" : ""
                    }`}
                    onClick={handleDay}
                  >
                    Wednesday - <span>{calculateDate("Wednesday")} </span>
                  </li>
                  {/* <li  value="Thursday" className={delday === "Thursday" ? "active" : "" }  onClick={handleDay}>Thursday</li> */}
                  <li
                    value="Friday"
                    className={`${delday === "Friday" ? "active" : ""} ${
                      isWithinTwoDays("Friday") ? "disabled" : ""
                    }`}
                    onClick={handleDay}
                  >
                    Friday - <span> ({calculateDate("Friday")})</span>
                  </li>
                  <li
                    value="Sunday"
                    className={`${delday === "Sunday" ? "active" : ""} ${
                      isWithinTwoDays("Sunday") ? "disabled" : ""
                    }`}
                    onClick={handleDay}
                  >
                    Sunday - <span>({calculateDate("Sunday")})</span>
                  </li>
                </ul>

                <p> Morning</p>
                <ul>
                  <li
                    value="1"
                    className={delslot === "1" ? "active" : ""}
                    onClick={handledelSlot}
                  >
                    5-7am
                  </li>
                  <li
                    value="2"
                    className={delslot === "2" ? "active" : ""}
                    onClick={handledelSlot}
                  >
                    7-9am
                  </li>
                </ul>

                {/* <p> Afternoon</p>
        <ul>
            <li value="3" className={delslot === "3" ? "active" : "" } onClick={handledelSlot}>12-6pm</li>
            {/* <li value="4" className={delslot === "4" ? "active" : "" }  onClick={handledelSlot}>3-6pm</li> 
        </ul> */}

                <p> Evening</p>
                <ul>
                  <li
                    value="3"
                    className={delslot === "3" ? "active" : ""}
                    onClick={handledelSlot}
                  >
                    5-7pm
                  </li>
                  <li
                    value="4"
                    className={delslot === "4" ? "active" : ""}
                    onClick={handledelSlot}
                  >
                    7-9pm
                  </li>
                </ul>
              </div>
            </form>
          </div>

          <div className="formbutton">
            <button type="submit" onClick={handleSubmit}>
              Next
            </button>
          </div>
        </div>

        {/* <dialog className="filter" ref={filterRef} id="filterref">
  <div className="modalTop">Change Delivery Date<button className="close" onClick={toggleDialog}>X</button></div>
        
       <div className="modalBody">    
        <ul>
            <li  value="Monday" className={delday === "Monday" ? "active" : "" } onClick={handleDay}>Monday</li>
            <li  value="Tuesday" className={delday === "Tuesday" ? "active" : "" }  onClick={handleDay}>Tuesday</li>
            <li  value="Wednesday" className={delday === "Wednesday" ? "active" : "" }    onClick={handleDay}>Wednesday</li>
            <li  value="Thursday" className={delday === "Thursday" ? "active" : "" }  onClick={handleDay}>Thursday</li>
            <li  value="Friday" className={delday === "Friday" ? "active" : "" }  onClick={handleDay}>Friday</li>
        </ul>

        <p> Morning</p>
        <ul>
            <li value="1" className={delslot === "1" ? "active" : "" }  onClick={handledelSlot}>6-9am</li>
            <li value="2" className={delslot === "2" ? "active" : "" }  onClick={handledelSlot}>9-12am</li>
        </ul>

        <p> Afternoon</p>
        <ul>
            <li value="3" className={delslot === "3" ? "active" : "" } onClick={handledelSlot}>12-3pm</li>
            <li value="4" className={delslot === "4" ? "active" : "" }  onClick={handledelSlot}>3-6pm</li>
        </ul>

        <p> Evening</p>
        <ul>
            <li value="5" className={delslot === "5" ? "active" : "" }  onClick={handledelSlot}>6-10pm</li>
          
        </ul>
      </div>
           
    
    </dialog> */}
      </div>
    </div>
  );
};

export default Deliver;
