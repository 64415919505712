import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [thanksMessage, setThanksMessage] = useState("");

  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSubmissionStatus(null);

    if (
      !formData.fName ||
      !formData.lName ||
      !formData.email ||
      !formData.message ||
      !formData.phone
    ) {
      setErrorMessage("All fields are required");
      return;
    }
    if (!isChecked) {
      setErrorMessage("Please accept the privacy policy");
      return;
    }

    try {
      await axios.post("/api/auth/send", formData);
      setThanksMessage("Email sent successfully!");
      setSubmissionStatus("success");
    } catch (error) {
      console.error("Error sending email", error);
      setThanksMessage("Failed to send email.");
      setSubmissionStatus("failure");
    }
  };
  const phoneNumber = "1234567890";
  const link = `https://wa.me/${phoneNumber}`;

  return (
    <div className="containerForm">
      <div className="left">
        <div className="leftContents">
          <h1 className="title">Talk to Us</h1>
          <h2>Need help? Got a question?</h2>
          <p>
            Contact us on <strong>(04) 123 456</strong>, and our friendly
            support team will be happy to help you.
          </p>
          <p>Our phone lines are available daily from 7 am to 8 pm.</p>
          <p>Otherwise, please use the form below to send us a message.</p>
          <p className="socials">Or contact us on social media</p>
          <div className="socialImgs">
            <Link to="https://www.instagram.com/cookit_ae/" target="_blank">
              <img
                className="newSVG"
                type="image/svg+xml"
                src="/images/instagram-167-svgrepo-com.svg"
                alt="instagram"
              />
            </Link>
            <img
              className="newSVG"
              type="image/svg+xml"
              src="/images/tiktok-svgrepo-com.svg"
              alt="tiktok"
            />
            <img
              className="newSVG"
              type="image/svg+xml"
              src="/images/facebook-176-svgrepo-com.svg"
              alt="facebook"
            />
            <a
              href="https://wa.me/1234567890"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="newSVG"
                type="image/svg+xml"
                src="/images/whatsapp-svgrepo-com.svg"
                alt="whatsapp"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="right">
        {submissionStatus ? (
          <div className={`message-container ${submissionStatus}`}>
            <h2>{thanksMessage}</h2>
            {submissionStatus === "failure" && <p>Please try again later.</p>}
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group position-relative">
              <input
                type="text"
                name="fName"
                placeholder="Your First Name"
                value={formData.fName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group position-relative">
              <input
                type="text"
                name="lName"
                placeholder="Your Last Name"
                value={formData.lName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group position-relative">
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group position-relative">
              <input
                type="text"
                name="phone"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group message">
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-group message privacy">
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                I agree to Cookit's <Link to="/privacy">Privacy Policy</Link>
              </label>
            </div>
            {errorMessage && (
              <p id="error" className="error">
                {errorMessage}
              </p>
            )}
            <div className="formbutton">
              <button type="submit" className="btn btn-primary" tabIndex="-1">
                Send message
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
