import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

const Orderhistory = () => {
  const { authState, refreshData } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const userId = authState.user._id;

  const [groupedOrders, setGroupedOrders] = useState({}); // State to store grouped orders

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await axios.get(`/api/auth/orderhistory/${userId}`);
        const rawOrders = response.data.data;
        console.log("rawOrders", rawOrders);
        // Group orders by weekstarting
        const grouped = rawOrders.reduce((acc, order) => {
          order.mealsByStartDate.forEach((mealSet) => {
            const { weekstarting } = mealSet;
            if (!acc[weekstarting]) {
              acc[weekstarting] = [];
            }
            acc[weekstarting].push(mealSet); // Group by weekstarting
          });
          return acc;
        }, {});

        setGroupedOrders(grouped);
        console.log("groupedOrders", groupedOrders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order history:", error);
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, [userId]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          <div className="userData">
            <div className="boxes orderHistory wrapper">
              <div className="container ">
                <div className="headerSection">
                  <h1>Order History</h1>
                  <Link to={"/dashboard"}>
                    <button className="btd">Back to dashboard</button>
                  </Link>
                </div>
                {Object.keys(groupedOrders).map((weekstarting) => (
                  <div key={weekstarting} className="weekOrder">
                    <h2>
                      Week Starting:{" "}
                      {new Date(weekstarting).toLocaleDateString()}
                    </h2>
                    {groupedOrders[weekstarting].map((mealSet) => (
                      <div key={mealSet._id} className="orderhistory-mealSet">
                        <h3>
                          Delivery Date:
                          {mealSet.deliveryDate
                            ? new Date(
                                mealSet.deliveryDate
                              ).toLocaleDateString()
                            : "N/A"}
                        </h3>
                        <div>
                          {mealSet.mealSelections.map((meal) => (
                            <div key={meal._id} className="orderhistory-meal">
                              <img
                                src={`/images/meals/${meal.meal.image}`}
                                alt={meal.meal.title}
                                style={{ width: "100px", margin: "5px" }}
                              />
                              <p className="mealTitle">{meal.meal.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderhistory;
