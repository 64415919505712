function calculateUpcomingDay(targetDay) {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let isToday = false;

  let daysToAdd;
  if (targetDay === "wednesday") {
    daysToAdd = (3 - dayOfWeek + 7) % 7;
  } else if (targetDay === "friday") {
    daysToAdd = (5 - dayOfWeek + 7) % 7;
  } else if (targetDay === "sunday") {
    daysToAdd = (6 - dayOfWeek + 7) % 7;
  } else {
    return null;
  }

  // Ensure days_to_add is positive
  if (daysToAdd <= 0) {
    isToday = true;
    daysToAdd += 6;
  }

  //console.log("daysToAdd:", daysToAdd);

  // Calculate the upcoming date starting from tomorrow
  const upcomingDate = new Date(today);
  upcomingDate.setDate(upcomingDate.getDate() + daysToAdd + 1);

  // Check if the upcoming date is more than 48 hours away from today
  const isMoreThan48HoursAway =
    (upcomingDate - today) / (1000 * 60 * 60 * 24) <= 2;

  return {
    upcomingDate,
    isMoreThan48HoursAway,
    isToday,
  };
}

export default calculateUpcomingDay;
