import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { getWeeklyRangesUntil, getCurrentWeekStartEnd } from "./dateUtility";
// import CheckDateStatus from "./checkDateinlastweek";
import UserPanel from "./userPanel";

const Mydelivery = (props) => {
  const [updateMessage, setUpdateMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [weeklyRanges, setWeeklyRanges] = useState([]);
  const { authState } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    deliveryDay: "",
    deliverTimeSlot: "",
  });
  const [delday, setDelday] = useState(authState.user.deliveryDay);
  const [delslot, setDelslot] = useState(authState.user.deliverTimeSlot);
  const [tdate, setTdate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [skipweekDate, setSkipweekDate] = useState("");
  const [skippedweek, setSkippedweek] = useState();
  const [dateStatus, setDateStatus] = useState();
  const [deliveryDate, setDeliveryDate] = useState("");
  const [nextDeliveryDate, setNextDeliveryDate] = useState(null);
  const navigateTo = useNavigate();
  const [error, setError] = useState(null);
  // console.log(authState)
  const userId = authState.user._id;
  const endDateString = authState.user.paymentDay;
  //console.log(authState.user)
  const skipweekDateD = authState.user.skipWeek;
  const [newdelDay, setNewdelDay] = useState("");
  const endDate = new Date(endDateString);
  const [dateChangeMessage, setDateChangeMessage] = useState("");
  const [sameDay, setSameDay] = useState(false);
  const [is48hours, setIs48hours] = useState(false);

  useEffect(() => {
    document.title = "My Delivery details";
    const { startdate, endate } = getCurrentWeekStartEnd();
    const ranges = getWeeklyRangesUntil(startdate, endate);
    // startOfWeek=ranges[0].start;
    // endOfWeek = ranges[0].end;
    setWeeklyRanges(ranges);

    // const startOfWeek = getMostRecentSunday();
    // const endOfWeek = getEndOfThisWeek();
    // const orderDate = async () => {
    //   try {
    //     const response = await axios.get(`/api/auth/deliverydates`, {
    //       params: { userId: userId },
    //     });
    //     const order = response.data;
    //     console.log("order", order);
    //     setDeliveryDate(order.deliveryDates[0]);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // orderDate();

    const fetchNextDeliveryDate = async () => {
      try {
        const response = await axios.get(
          `/api/auth/next-delivery-date?userId=${userId}`
        );
        if (response.data.nextDeliveryDate) {
          setNextDeliveryDate(response.data.nextDeliveryDate);
        } else if (response.data.message) {
          setError(response.data.message);
        }
      } catch (err) {
        setError("Error fetching next delivery date.");
        console.error(err);
      }
    };

    fetchNextDeliveryDate();
  }, []);

  const dialogsRef = useRef({});

  const toggleDialog = (id) => {
    const dialogRef = dialogsRef.current[id];
    console.log("dialogRef", dialogRef);
    if (!dialogRef) {
      return;
    }

    dialogRef.hasAttribute("open")
      ? dialogRef.classList.add("closing")
      : dialogRef.classList.remove("closing");
    dialogRef.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    dialogRef.hasAttribute("open") ? dialogRef.close() : dialogRef.showModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  }; // Prevent click event from propagating to backdrop };

  const reroute = () => {
    navigateTo("/dashboard");
  };

  const handleDay = (e) => {
    const selectedDay = e.target.getAttribute("value");
    if (selectedDay === authState.user.deliveryDay) {
      setSameDay(true);
    }

    console.log("Selected Day:", selectedDay, authState.user.deliveryDay);
    if (
      !e.target.classList.contains("active") &&
      authState.user.deliveryDay !== selectedDay
    ) {
      toggleDialog("dialog2");
      setFormData((prevData) => ({
        ...prevData,
        deliveryDay: selectedDay,
      }));
    }
    setDelday(selectedDay);
  };

  // const data = CheckDateStatus({ deliveryDate, onResult: setDateStatus });
  //console.log(deliveryDate);

  const handledelSlot = (e) => {
    setDelslot(e.target.getAttribute("value"));

    setFormData((prevData) => ({
      ...prevData,
      deliverTimeSlot: e.target.getAttribute("value"),
    }));
  };

  useEffect(() => {
    if (sameDay === false) {
      // console.log("next delivery Day:", nextDeliveryDate);
      console.log("next delivery Day:", delday);
      let targetDay;
      switch (delday) {
        case "Sunday":
          targetDay = 0;
          break;

        case "Wednesday":
          targetDay = 3;
          break;

        case "Friday":
          targetDay = 5;
          break;

        default:
          console.error("Invalid delivery day:", delday);
          return;
      }

      const calculateNextDate = (targetDay, addWeek = false) => {
        console.log("targetDay", targetDay);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

        let daysUntilNext = targetDay - today.getDay();
        console.log("daysUntilNext", daysUntilNext);

        if (daysUntilNext < 3 || (daysUntilNext === 0 && !dateStatus)) {
          daysUntilNext += 7; // Move to the next week if the day has already passed or is today and should move to next occurrence
        }

        if (addWeek) {
          daysUntilNext += 7; // Add another week if addWeek is true
        }

        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + daysUntilNext);
        console.log("Next Date:", nextDate);
        setTdate(nextDate.toLocaleDateString());

        return nextDate;
      };

      //calculateNextDate(nextDeliveryDate);

      const isWithin48Hours = () => {
        // const [day, month, year] = nextDeliveryDate.split("/").map(Number);
        // const targetDate = new Date(year, month - 1, day);
        //const targetDate = new Date(nextDeliveryDate);
        //console.log("Target Date:", targetDate);
        const now = new Date();
        const todayDay = now.getDay();
        const diffDays = targetDay - todayDay;
        console.log("diffDays", diffDays);
        // Calculate the difference in time (milliseconds)
        //const diffTime = targetDate - now;
        // Convert the difference in time to days
        //const diffDays = diffTime / (1000 * 3600 * 24);
        // Check if the difference in days is 2 or less
        // console.log("Difference in days:", diffDays);
        if (diffDays <= 2) {
          setIs48hours(true);
          setDateChangeMessage(
            `You are unable to modify the delivery schedule as it is within the 48-hour window. However, the requested changes will automatically be applied to the corresponding delivery in the following week.`
          );
          calculateNextDate(targetDay, true);
        } else {
          setIs48hours(false);
          setDateChangeMessage(`Delivery would become ${tdate}`);
          calculateNextDate(targetDay, false);
        }
        return diffDays <= 2;
      };
      // //console.log("Next Delivery Date:", nextDeliveryDate);
      if (nextDeliveryDate && targetDay != delday) {
        isWithin48Hours(tdate);
      }
    }
    setSameDay(false);
  }, [delday, nextDeliveryDate, tdate]);

  const handleClick = async (id) => {
    console.log(userId, delday, delslot);
    toggleDialog(id);
    setLoading(true);
    console.log("sameday", sameDay);
    if (sameDay === true) {
      reroute();
    } else {
      if (is48hours) {
        try {
          const response = await axios.put(
            `/api/auth/updateUserDelivery/${userId}`,
            {
              deliveryDay: delday,
              deliverTimeSlot: delslot,
            }
          );

          setUpdateMessage(response.data.message);
          setLoading(false);
        } catch (error) {
          console.error(`Error updating meal selections:`, error);
        }
      } else {
        try {
          const response = await axios.put(
            `/api/auth/updateUserDelivery/${userId}`,
            {
              deliveryDay: delday,
              deliverTimeSlot: delslot,
              deliveryDate: tdate,
            }
          );

          setUpdateMessage(response.data.message);
          setLoading(false);
        } catch (error) {
          console.error(`Error updating meal selections:`, error);
        }
      }
    }
  };

  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          {/* <div className="userLinks">
                <UserPanel />
                </div> */}

          <div className="userData">
            <div className="boxes wrapper">
              <div className="container ">
                <div className="headerSection">
                  <h1>Delivery</h1>
                  <h2>Edit your delivery</h2>
                  <Link to={"/dashboard"}>
                    <button className="btd">Back to dashboard</button>
                  </Link>
                </div>
                {/* <CheckDateStatus
                  deliveryDate={deliveryDate}
                  onResult={handleResult}
                /> */}
                <p>Next delivery day: {nextDeliveryDate}</p>
                <div className="deliveryPanels delivery">
                  <div className="deliveryPanel">
                    <h3>Select your preffered delivery day</h3>
                    <p>
                      <strong>
                        <span>
                          Please note, if your next delivery is in 48 hours or
                          less, this change wont be reflected until the delivery
                          after your next delivery.
                        </span>
                      </strong>
                    </p>
                    <ul>
                      <li
                        value="Sunday"
                        className={delday === "Sunday" ? "active" : ""}
                        onClick={handleDay}
                      >
                        Sunday
                      </li>
                      {/* <li  value="Monday" className={delday === "Monday" ? "active" : "" } onClick={handleDay}>Monday</li>
            <li  value="Tuesday" className={delday === "Tuesday" ? "active" : "" }  onClick={handleDay}>Tuesday</li> */}
                      <li
                        value="Wednesday"
                        className={delday === "Wednesday" ? "active" : ""}
                        onClick={handleDay}
                      >
                        Wednesday
                      </li>
                      {/* <li  value="Thursday" className={delday === "Thursday" ? "active" : "" }  onClick={handleDay}>Thursday</li> */}
                      <li
                        value="Friday"
                        className={delday === "Friday" ? "active" : ""}
                        onClick={handleDay}
                      >
                        Friday
                      </li>
                    </ul>

                    <h3> Select your preffered delivery time slot</h3>
                    <h4> Morning</h4>
                    <ul>
                      <li
                        value="1"
                        className={delslot === "1" ? "active" : ""}
                        onClick={handledelSlot}
                      >
                        5-7am
                      </li>
                      <li
                        value="2"
                        className={delslot === "2" ? "active" : ""}
                        onClick={handledelSlot}
                      >
                        7-9am
                      </li>
                    </ul>

                    {/* <h4> Afternoon</h4>
        <ul>
            <li value="3" className={delslot === "3" ? "active" : "" } onClick={handledelSlot}>12-3pm</li>
            <li value="4" className={delslot === "4" ? "active" : "" }  onClick={handledelSlot}>3-6pm</li>
        </ul> */}

                    <h4> Evening</h4>
                    <ul>
                      <li
                        value="3"
                        className={delslot === "3" ? "active" : ""}
                        onClick={handledelSlot}
                      >
                        5-7pm
                      </li>
                      <li
                        value="4"
                        className={delslot === "4" ? "active" : ""}
                        onClick={handledelSlot}
                      >
                        7-9pm
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="saveButtons">
                  <button
                    className="nextButton"
                    onClick={() => handleClick("dialog1")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dialog
        className="filter"
        ref={(el) => (dialogsRef.current["dialog2"] = el)}
        id="DateRef"
        onClick={() => {
          toggleDialog("dialog2");
        }}
      >
        <div className="modalTop" onClick={handleContentClick}>
          <span>Confirm date change</span>
          <button
            className="close"
            onClick={() => {
              toggleDialog("dialog2");
            }}
          >
            X
          </button>
        </div>
        <div className="modalBody" onClick={handleContentClick}>
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{dateChangeMessage}</p>
          )}
        </div>
      </dialog>

      <dialog
        className="filter"
        ref={(el) => (dialogsRef.current["dialog1"] = el)}
        id="PaymentRef"
        onClick={() => {
          toggleDialog("dialog1");
          reroute();
        }}
      >
        <div className="modalTop" onClick={handleContentClick}>
          <span>Updating delivery</span>
          <button
            className="close"
            onClick={() => {
              toggleDialog("dialog1");
              reroute();
            }}
          >
            X
          </button>
        </div>
        <div className="modalBody" onClick={handleContentClick}>
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{updateMessage}</p>
          )}
        </div>
      </dialog>
    </>
  );
};

export default Mydelivery;
