import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "../styles/register.css";
import axios from "axios";
import { FormContext } from "./FormContext";

const Checkout = () => {
  //const { createUser, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);
  const [ccNumber, setccNumber] = useState("");
  const [ccName, setccName] = useState("");
  const [ccCVV, setccCVV] = useState("");
  const [ccExpiryDate, setCcexp] = useState("");
  const [lfn, setLfn] = useState("");
  const [paymentDay, setPaymentDay] = useState("");
  const [ccnoerrorMessage, setccnoerrorMessage] = useState("");
  const [ccNameerrorMessage, setccNameerrorMessage] = useState("");
  const [ccCVVerrorMessage, setccCVVerrorMessage] = useState("");
  const [ccexperrorMessage, setccexperrorMessage] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [checked, setChecked] = useState(true);
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [add1Message, setAdd1Message] = useState("");
  const [add2Message, setAdd2Message] = useState("");
  const [cityMessage, setCityMessage] = useState("");
  const [areaMessage, setAreaMessage] = useState("");
  const [streetMessage, setStreetMessage] = useState("");
  const [street, setStreet] = useState("");
  const PaymentRef = useRef();
  const [key, setKey] = useState(Math.random());
  const [ccdata, setCcdata] = useState({});
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const email = formData.email;
  const password = formData.password;
  const location = useLocation();
  const neworderData = location.state.orderData;
  const [fullorderData, setFullOrderData] = useState({});
  console.log(`form data`, formData);
  const [newFullOrderData, setNewFullOrderData] = useState({});
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    document.querySelector(".signupNav").style.display = "none";
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "ccNumber" || name === "ccCVV") {
      value = value.replace(/[^0-9]/g, "");
    }

    if (name === "ccName") {
      value = value.replace(/[0-9]/g, "");
    }

    e.target.nextSibling.style.display = "none";

    setNewFullOrderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    switch (name) {
      case "add1":
        setAdd1(value);
        break;
      case "add2":
        setAdd2(value);
        break;
      case "city":
        setCity(value);
        break;

      case "street":
        setStreet(value);
        break;
      case "paymentDay":
        setPaymentDay(value);
        break;
    }
  };

  function toggleDialog(e) {
    e.preventDefault();
    if (!checked) {
      if (!add1 || !add2 || !city || !area || !street) {
        if (!add1) {
          document.querySelector(".add1").style.display = "block";
          setAdd1Message("Please enter address");
        }

        if (!add2) {
          document.querySelector(".add2").style.display = "block";
          setAdd2Message("Please enter address");
        }
        if (!city) {
          document.querySelector(".city").style.display = "block";
          setCityMessage("Please enter city");
        }

        if (!street) {
          document.querySelector(".street").style.display = "block";
          setStreetMessage("Please enter street");
        }
      }
    }

    handleClick();
  }

  const handleClick = async () => {
    setFormData([]);
    localStorage.clear();
    let payload;
    if (!checked) {
      payload = {
        amount: neworderData.total * 100,
        items: [
          {
            name: "Meals",
            amount: neworderData.total * 100,
            description: `${formData.mealSelections.length} Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: formData.billingadd1,
          addressLine2: formData.billingadd2,
          first_name: formData.fname,
          last_name: formData.lname,
          street: formData.billingstreet,
          town: formData.billingcity,
          phone_number: formData.contact,
          country: "UAE",
          email: formData.email,
        },
        customer: {
          first_name: formData.fname,
          last_name: formData.lname,
          email: formData.email,
        },
      };
    } else {
      payload = {
        amount: neworderData.total * 100,
        items: [
          {
            name: "Meals",
            amount: neworderData.total * 100,
            description: `${formData.mealSelections.length} Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          first_name: formData.fname,
          last_name: formData.lname,
          street: formData.street,
          town: formData.town,
          phone_number: formData.contact,
          country: "UAE",
          email: formData.email,
        },
        customer: {
          first_name: formData.fname,
          last_name: formData.lname,
          email: formData.email,
        },
      };
    }
    console.log(`payload`, payload);

    try {
      const payResponse = await axios.post("/api/auth/payment", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(payResponse.data);

      const orderId = payResponse.data.payment_keys[0].order_id;
      console.log("Extracted order_id:", orderId);
      const updatedOrderData = { ...neworderData, paymentId: orderId };
      console.log("Updated order data:", updatedOrderData);

      // Set client secret and update order data
      setClientSecret(payResponse.data.client_secret);
      setFullOrderData(updatedOrderData); // Update the state with the new order data
      console.log("Full order data1:", fullorderData);
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
      }
    }
  };

  useEffect(() => {
    if (fullorderData.paymentId && clientSecret) {
      console.log("Full order data2:", fullorderData);
      const sendDataToDatabase = async () => {
        try {
          const response = await axios.post(
            "/api/auth/createTempOrder",
            fullorderData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("Data sent to database:", response.data);

          // Redirect to payment page
          window.location.href = `https://uae.paymob.com/unifiedcheckout/?publicKey=are_pk_test_IUUNrDEOL7fiDIE9mHncauf607Epi5Q6&clientSecret=${clientSecret}`;
        } catch (error) {
          console.error("Error sending data to database:", error);
        }
      };

      sendDataToDatabase();

      // setFormData("");
      // setFullOrderData("");
    }
  }, [fullorderData, clientSecret]);

  const paymentFailure = () => {
    setPaid(false);
  };
  // const goToDashboard = () => {
  //   login(email, password);
  //    navigate('/dashboard');
  // }

  const handleCheck = (e) => {
    setChecked(!checked);

    if (!checked) {
      document.querySelector(".billingAddress").style.display = "flex";
    } else {
      document.querySelector(".billingAddress").style.display = "none";
    }
  };

  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const cc_format = (value) => {
    const v = value.replace(/[^0-9]/gi, "").substr(0, 16);

    const parts = [];
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }
    return parts.length > 1 ? parts.join(" - ") : value;
  };

  return (
    <>
      {!paid ? (
        <>
          <div className="containerForm payment">
            <div className="left">
              <div className="leftContents">
                <h1>Payment</h1>
                <h2>Card details</h2>

                <img
                  className="payIcon"
                  src="../images/icons/creditcards.png"
                  alt="credit card"
                />
              </div>
            </div>

            <div className="right">
              <form>
                <div className="paymentForm">
                  {/* <div className='errorContainer'>  <input type="text"id="ccName" name="ccName" placeholder="Enter Name On Card" value={ccName} onChange={handleChange} />
                     
                      <div className="error fnerror inputerror ccna">{ccNameerrorMessage}</div>
                      <i className="bi bi-person" aria-hidden="true"></i>
                    </div>
                     <div className='errorContainer'>  
                      <input type="text"  input-mask="credit-card"  id="ccNumber"  name="ccNumber" placeholder="XXXX-XXXX-XXXX-XXXX" value={cc_format(ccNumber)} onChange={handleChange} />
                     <div className="error fnerror inputerror ccno">{ccnoerrorMessage}</div>
                     <i className="bi bi-credit-card" aria-hidden="true"></i>
                     </div>
            
                      <div className='errorContainer'> <input type="text" id="ccExpiryDate" name="ccExpiryDate" placeholder="mm/yyyy" value={expriy_format(ccExpiryDate)}  onChange={handleChange} />
                        <div className="error fnerror inputerror ccex">{ccexperrorMessage}</div>
                        <i className="bi bi-calendar3" aria-hidden="true"></i>
                      </div>

                      <div className='errorContainer'> <input type="password" id="ccCVV" name="ccCVV" placeholder="Enter cvv" maxLength="3" value={ccCVV}  onChange={handleChange}/> 
                        <div className="error fnerror inputerror ccvv">{ccCVVerrorMessage}</div>
                        <i className="bi bi-credit-card-2-back" aria-hidden="true"></i>
                      </div> */}

                  <h3>Billing address</h3>
                  <div className="checkboxAddress">
                    <input
                      type="checkbox"
                      id="sameAddress"
                      name="sameAddress"
                      onChange={handleCheck}
                    />
                    <label htmlFor="sameAddress">
                      Same as delivery address
                    </label>
                  </div>
                  <div className="billingAddress">
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add1"
                        name="add1"
                        placeholder="Enter Address Line 1"
                        value={add1}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add1">
                        {add1Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add2"
                        name="add2"
                        placeholder="Enter Address Line 2"
                        value={add2}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add2">
                        {add2Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="street"
                        name="street"
                        placeholder="Enter Street Name"
                        value={street}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror street">
                        {streetMessage}
                      </div>
                    </div>

                    <div className="errorContainer">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Enter City"
                        value={city}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror city">
                        {cityMessage}
                      </div>
                    </div>
                  </div>
                  <button onClick={toggleDialog}>Place Order</button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : !paymentSuccess ? (
        <>
          <div className="containerForm payment">
            <div className="flex paySuccess">
              <h1>Payment Unsuccessful</h1>
              <h2>
                Thank you for wanting to join. unfortunately the payment has not
                been successful
              </h2>
              <div>
                <button onClick={paymentFailure}>Please try again</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="containerForm payment">
            <div className="flex paySuccess">
              <h1>Payment Successful</h1>
              <h2>
                Thank you for joining us. You can now view your{" "}
                <Link to={"/dashboard"}>Dashboard</Link> to view your meals.
                Click the button below to go to your dashboard
              </h2>
              {/* <div><button onClick={goToDashboard}>Go to Dashboard</button></div> */}
            </div>
          </div>
        </>
      )}

      <dialog className="filter" ref={PaymentRef} id="PaymentRef">
        <div className="modalTop">
          <span>Processing payment</span>
          <button className="close" onClick={toggleDialog}>
            X
          </button>
        </div>
        <div className="modalBody">
          <p>Processing Payment</p>
          <img
            src="../images/icons/loading.gif"
            alt="loading"
            className="loading"
          />
          <p>{paymentError}</p>
        </div>
      </dialog>
    </>
  );
};

export default Checkout;
