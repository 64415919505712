import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FormContext } from "./FormContext";
import "../styles/register.css";
import { use } from "bcrypt/promises";

export const Boxes = () => {
  // useEffect(() => {
  //   setInRegister(["0", "1"]);
  // }, []);
  const { formData, setFormData } = useContext(FormContext);
  const { authState, registerState } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const getpeople = formData.people;
  const getmeals = formData.nmeals;

  const [pricepermeal, setPricepermeal] = useState(50);
  const [gourmetPrice, setGourmetPrice] = useState(10);
  const [people, setPeople] = useState(getpeople);
  const [meals, setMeals] = useState(getmeals);

  let totalPrice = people * getmeals * pricepermeal;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);

  useEffect(() => {
    console.log(getpeople, getmeals);
    if (
      getpeople !== undefined &&
      getmeals !== undefined &&
      getpeople !== null &&
      getmeals !== null &&
      getpeople !== "" &&
      getmeals !== "" &&
      getpeople !== 0 &&
      getmeals !== 0
    ) {
      document.querySelector(".boxesData").style.display = "block";
      document.querySelector(".mealsData").style.display = "block";
      if (document.querySelector(".error")) {
        document.querySelector(".error").remove();
      }
    }
  }, [getpeople, getmeals]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      currentStep: 0,
    }));
  }, []);

  const handleNext = () => {
    if (!getpeople || !getmeals) {
      // setErrorMessage(`Please select a box size and number of people`)
      document.querySelector(
        ".errorContainer"
      ).innerHTML = `<p class='error'>Please select a box size and the number of people you are cooking for.</p>`;
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      total: totalPrice,
    }));
    navigate("/preferences");
  };

  const handlePeopleChange = (e) => {
    handleChange(e);
    setPeople(e.target.value);
  };

  const handleMealsChange = (e) => {
    setMeals(e.target.value);
    handleChange(e);
    switch (e.target.value) {
      case "3":
        setPricepermeal((prevState) => 50);
        break;
      case "4":
        setPricepermeal((prevState) => 40);
        break;
      case "5":
        setPricepermeal((prevState) => 30);
        break;
      default:
        setPricepermeal((prevState) => 50);
    }
  };

  return (
    <>
      <div className="boxes wrapper">
        <div className="container ">
          <h1>Boxes</h1>

          <h2>Select your box</h2>

          <div className="boxesPeople">
            <h3>How many people are you cooking for?</h3>
            <small className="mealsMsg">
              (Our minimum order is 2 servings)
            </small>
            <div className="radioGroup">
              <input
                type="radio"
                value="2"
                name="people"
                id="noofpeopletwo"
                onChange={handlePeopleChange}
                checked={getpeople === "2"}
              />
              <label htmlFor="noofpeopletwo">2</label>
              <input
                type="radio"
                value="3"
                name="people"
                id="noofpeoplethree"
                onChange={handlePeopleChange}
                checked={getpeople === "3"}
              />
              <label htmlFor="noofpeoplethree">3</label>
              <input
                type="radio"
                value="4"
                name="people"
                id="noofpeoplefour"
                onChange={handlePeopleChange}
                checked={getpeople === "4"}
              />
              <label htmlFor="noofpeoplefour">4</label>
            </div>
          </div>
          <div className="boxesPeople">
            <h3>How many recipes per week? </h3>

            <div className="radioGroup">
              <input
                type="radio"
                value="3"
                className="setMeals"
                name="nmeals"
                id="noofMealsthree"
                onChange={handleMealsChange}
                checked={getmeals == "3"}
              />
              <label htmlFor="noofMealsthree">3</label>
              <input
                type="radio"
                value="4"
                className="setMeals"
                name="nmeals"
                id="noofMealsfour"
                onChange={handleMealsChange}
                checked={getmeals == "4"}
              />
              <label htmlFor="noofMealsfour">4</label>
              <input
                type="radio"
                value="5"
                className="setMeals"
                name="nmeals"
                id="noofMealsfive"
                onChange={handleMealsChange}
                checked={getmeals == "5"}
              />
              <label htmlFor="noofMealsfive">5</label>
            </div>
          </div>
          <div className="mealsData">
            <p>
              You can cook <span>{people * meals}</span> servings per week.
            </p>
            <p>
              You will get a box with <span>{meals}</span> servings for{" "}
              <span>{people}</span> people
            </p>
          </div>

          <div className="boxesData">
            <div className="boxesPrice">
              <p>Price per serving: {pricepermeal}AED</p>
              <hr></hr>
              <p>
                {people * meals} Servings{" "}
                <span>{people * meals * pricepermeal}AED</span>
              </p>
              <p>
                Delivery <span>Free</span>
              </p>
              <hr></hr>
              <p className="totalPrice">
                Total Price <span>{totalPrice}AED*</span>
              </p>
              <p className="warning">
                *Selecting gourmet meals will incur an extra {gourmetPrice}AED
              </p>
            </div>
          </div>
          <div className="errorContainer"></div>

          <button className="nextButton" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Boxes;
