import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "../styles/meals.css";
import axios from "axios";
import WeeklyDateRanges from "./weeklydaterange";
import Loading from "./loading";
import MealModal from "./mealModal"; // Import the MealModal component

const Themeals = () => {
  const [loading, setLoading] = useState(true);
  const [MealsList, setMealsList] = useState([]);
  const [filteredMealsList, setFilteredMealsList] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null); // Change state to store selected meal object
  const [message, setMessage] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const filters = [
    "family friendly",
    "kid friendly",
    "low carb",
    "vegetarian",
    "pescatarian",
    "calorie smart",
    "classics",
    "guilt free",
    "chicken",
    "meat",
    "gourmet",
    "breakfast",
    "clear filter",
  ];

  useEffect(() => {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var dayOfWeek = today.getDay();
    if (dayOfWeek >= 5) {
      // If it's Friday, return the next Monday
      today.setDate(today.getDate() + (8 - dayOfWeek));
    } else {
      // Otherwise, calculate the most recent past Monday
      today.setDate(today.getDate() - (dayOfWeek - 1));
    }
    var lastOrNextMonday = new Date(today);
    var lastSunday = lastOrNextMonday;
    const offset = lastSunday.getTimezoneOffset();
    lastSunday = new Date(lastSunday.getTime() - offset * 60 * 1000);
    lastSunday = lastSunday.toISOString().split("T")[0];
    handleGetRequest(lastSunday);
  }, []);

  useEffect(() => {
    filterItems();
  }, [selectedFilters, filteredMealsList]);

  const handleFilter = (selectedCategory) => {
    if (selectedCategory === "clear filter") {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(selectedCategory)) {
        const filters = selectedFilters.filter((el) => el !== selectedCategory);
        setSelectedFilters(filters);
      } else {
        setSelectedFilters([...selectedFilters, selectedCategory]);
      }
    }
  };

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      const tempItems = selectedFilters.map((selectedCategory) => {
        const temp = filteredMealsList.filter((item) =>
          item.mealtype.some((type) => type.includes(selectedCategory))
        );
        return temp;
      });

      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
    } else {
      setMealsList(filteredMealsList);
    }
  };

  const handleGetRequest = async (lastSunday) => {
    try {
      const response = await axios.get(`/api/auth/newMeals`, {
        params: {
          dateUsed: lastSunday,
        },
      });
      // if (response.data) {
      const mealsData = response.data.mergedResults;
      console.log(mealsData.length);
      // setMealsList(mealsData);
      // setFilteredMealsList(mealsData);
      if (mealsData.length === 0) {
        setMealsList([]);

        setMessage("No matched meals");
      } else {
        setFilteredMealsList(mealsData);
        setMealsList(mealsData);
        setMessage("");
      }
    } catch (error) {
      console.log(error);
      setMealsList([]);
      setMessage("No meals found");
    }
    setLoading(false);
  };

  const containsGourmet = (mealtype) => {
    if (Array.isArray(mealtype) && mealtype.length > 0) {
      const typesArray = mealtype.flatMap((item) =>
        item.split(",").map((subItem) => subItem.trim().toLowerCase())
      );
      return typesArray.includes("gourmet");
    }
    return false;
  };

  const handleExp = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="container themeals">
        <h1>
          <span>Our Meals</span>
        </h1>
        <div className="datesContainer">
          <WeeklyDateRanges onRequest={handleGetRequest} />
        </div>
        <div className="filterContainer">
          <ul className="filters">
            {filters.map((category, idx) => (
              <li
                className={`filterbutton ${category.replace(/ /g, "")} ${
                  selectedFilters?.includes(category) ? "active" : ""
                }`}
                key={`filters-${idx}`}
                onClick={() => handleFilter(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>
        <div className="mealList">
          {loading ? <Loading /> : message && <p>{message}</p>}
          {MealsList.map((meal, index) => {
            const baseNameF = meal.image.split(".")[0]; // Extract base name from image file name
            const ext = meal.image.split(".")[1]; // Extract extension from image file name
            const baseName = `${baseNameF}-x`; // Add '-x' to baseName
            return (
              <div
                key={meal._id}
                id={meal._id}
                className={`mealCard ${
                  containsGourmet(meal.mealtype) ? "gourmet" : ""
                } ${activeIndex === index ? "expanded" : ""}`}
                onClick={() => {
                  setSelectedMeal(meal); // Set selected meal object
                }}
              >
                <div className="imgContainer">
                  <div className="mealsOverlay">View meal</div>
                  <img
                    src={`/images/meals/${baseName}.${ext}`} // Default to large image
                  />
                </div>

                <div className="contentContainer">
                  <div className="mealDetails">
                    <h4>{meal.title}</h4>
                    <p>{meal.subtitle}</p>
                  </div>
                  <div className="expdata">
                    <div className="expLeft">
                      <div className="mealDescription">
                        <p>{meal.desc}</p>
                      </div>
                    </div>
                    <div className="expRight">
                      <div className="expingredients">
                        <h4>Ingredients</h4>
                        <ul>
                          {meal.ingredients.map((ingredient, index) => {
                            const trimmedIngredient = ingredient.name.trim();
                            return trimmedIngredient ? (
                              <li key={index}>{trimmedIngredient}</li>
                            ) : null;
                          })}
                        </ul>
                      </div>
                      <div className="expallergens">
                        <h4>Allergens</h4>
                        <ul>
                          {meal.allergens &&
                            meal.allergens.split(",").map((type, index) => {
                              const trimmedType = type.trim();
                              return trimmedType ? (
                                <li
                                  key={index}
                                  className={trimmedType.replace(/ /g, "")}
                                >
                                  {trimmedType}
                                </li>
                              ) : null;
                            })}
                        </ul>
                      </div>
                      <div className="expnutrition">
                        <h4>Nutrition</h4>
                        <ul>
                          <li>Protein: {meal.protein}</li>
                          <li>Calories: {meal.energykcal}</li>
                          <li>Fat: {meal.fat}</li>
                          <li>Carbohydrates: {meal.carbs}</li>
                          <li>Of which sugars: {meal.ofwhichsugars}</li>
                          <li>Sodium: {meal.salt}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mealtypes">
                    <ul>
                      {meal.mealtype
                        .toString()
                        .split(",")
                        .map((type, index) => (
                          <li key={index} className={type.replace(/ /g, "")}>
                            {type}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {selectedMeal && (
        <MealModal meal={selectedMeal} onClose={() => setSelectedMeal(null)} />
      )}
    </>
  );
};

export default Themeals;
