import React, { useEffect, useState } from "react";
import "../styles/daterange.css";

function getLastMonday() {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dayOfWeek = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

  // console.log("Today's day of the week:", dayOfWeek);

  // If today is Friday (5), Saturday (6), or Sunday (0), set today to the next Monday
  if (dayOfWeek >= 5 || dayOfWeek === 0) {
    // Calculate the number of days to add to get to the next Monday
    const daysToAdd = (8 - dayOfWeek) % 7;
    today.setDate(today.getDate() + daysToAdd);
  } else {
    // Otherwise, calculate the most recent past Monday
    today.setDate(today.getDate() - (dayOfWeek - 1));
  }

  //console.log("Last Monday or Next Monday:", today);
  return today;
}

function getMondaysForNext52Weeks() {
  let mondays = [];
  let lastMonday = getLastMonday();

  // Iterate over a range of 52 weeks
  for (let i = 0; i < 52; i++) {
    let currentMonday = new Date(lastMonday);
    currentMonday.setDate(lastMonday.getDate() + i * 7);
    mondays.push(currentMonday);
  }

  //console.log("Mondays for next 52 weeks:", mondays);
  return mondays;
}

function formatDate(date) {
  const options = { day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

function getCurrentWeekIndex(mondays) {
  let today = new Date();
  const dayOfWeek = today.getDay();

  //console.log("Today before adjustment:", today);

  // If today is Friday (5), Saturday (6), or Sunday (0), set today to the next Monday
  if (dayOfWeek >= 5 || dayOfWeek === 0) {
    const daysToAdd = (8 - dayOfWeek) % 7;
    today.setDate(today.getDate() + daysToAdd);
  }

  // console.log("Today after adjustment:", today);

  // Iterate over the array of Mondays
  for (let i = 0; i < mondays.length; i++) {
    let nextMonday = new Date(mondays[i]);
    nextMonday.setDate(mondays[i].getDate() + 7);

    if (today >= mondays[i] && today < nextMonday) {
      //console.log("Current week index:", i);
      return i;
    }
  }

  // console.log("Current week index not found");
  return -1;
}

function getMonthsInRange(start, end) {
  let startMonth = start.toLocaleString("default", { month: "long" });
  let endMonth = end.toLocaleString("default", { month: "long" });

  if (startMonth === endMonth) {
    return startMonth;
  } else {
    return `${startMonth} - ${endMonth}`;
  }
}

const WeeklyDateRanges = ({
  onRequest,
  passedIndex,
  calRange,
  onRangeStart,
}) => {
  const dispRange = calRange || 4;
  const [weekRanges, setWeekRanges] = useState([]);
  const [selectedIsoDate, setSelectedIsoDate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let mondays = getMondaysForNext52Weeks();
    let currentWeekIndex = getCurrentWeekIndex(mondays);

    // console.log("Current week index:", currentWeekIndex);

    if (currentWeekIndex !== -1) {
      let ranges = mondays
        .slice(currentWeekIndex, currentWeekIndex + dispRange)
        .map((monday, index) => {
          let sunday = new Date(monday);
          sunday.setDate(monday.getDate() + 6);

          return {
            week: currentWeekIndex + index + 1,
            start: formatDate(monday),
            end: formatDate(sunday),
            months: getMonthsInRange(monday, sunday),
            isoDate: monday.toISOString().split("T")[0],
          };
        });

      //console.log("Week ranges:", ranges);
      setWeekRanges(ranges);

      // Pass the range start (first Monday) to the parent component
      if (onRangeStart && ranges.length > 0) {
        onRangeStart(ranges[0].isoDate);
      }
    } else {
      //console.log("No valid week ranges found.");
      setWeekRanges([]); // Set to an empty array if no valid week is found
    }
  }, [dispRange, onRangeStart]);

  useEffect(() => {
    // console.log("useEffect in WeeklyDateRanges triggered");
    // console.log("passedIndex:", passedIndex);
    // console.log("dispRange:", dispRange);
    // console.log("onRangeStart:", onRangeStart);
    // ... (rest of the code)
  }, [passedIndex, dispRange, onRangeStart]);

  const handleClick = (index, isoDate, event) => {
    event.stopPropagation(); // Stop event propagation
    setSelectedIndex(index);
    setSelectedIsoDate(isoDate);
    onRequest(isoDate, index);
  };

  return (
    <div className="dateRange">
      <ul>
        {weekRanges.map((range, index) => (
          <li
            key={range.isoDate}
            className={selectedIndex === index ? "selected" : ""}
            onClick={(event) => {
              handleClick(index, range.isoDate, event);
            }}
          >
            <span>
              {range.start}-
              {range.months.includes(" - ")
                ? range.months.split(" - ")[0] +
                  " - " +
                  range.end +
                  " " +
                  range.months.split("-")[1]
                : range.end + " " + range.months}{" "}
            </span>{" "}
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WeeklyDateRanges;
